import {
	ChangeDetectionStrategy,
	Component,
	computed,
	DestroyRef,
	inject,
	OnInit,
	Signal,
	signal,
	ViewChild,
	WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FollowBuildingsMenuComponent } from '@layout/au-nav-sidebar/navbar-menu-items/follow-buildings-menu/follow-buildings-menu.component';
import { MatIconModule } from '@angular/material/icon';
import { MenuItemContentComponent } from '@layout/au-nav-sidebar/navbar-menu-items/menu-item-content/menu-item-content.component';
import { MenuSectionItemTitleComponent } from '@layout/au-nav-sidebar/navbar-menu-items/menu-section-item-title/menu-section-item-title.component';
import { getFollowedBuildings } from '@auth/state/auth.selectors';
import { IAuNavbarConfig } from '@app/configs/au-navbar.config';
import { Store } from '@ngrx/store';
import { IOption } from '@shared/interfaces/selectable-options.interfaces';
import { UnfollowBuilding, UpdateFollowedBuildings } from '@auth/state/auth.actions';
import { MatMenuModule } from '@angular/material/menu';
import { NavbarMenuService } from '@layout/au-nav-sidebar/navbar-menu.service';
import { timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectCurrentBuildingId } from '@app/pages/views/state/views.reducer';
import { FeatureFlagService } from '@app/core/services/feature-flag.service';
import { NavbarNavigationService } from '../../navbar-navigation-service';

@Component({
	selector: 'my-buildings-section',
	standalone: true,
	imports: [
		CommonModule,
		FollowBuildingsMenuComponent,
		MatIconModule,
		MenuItemContentComponent,
		MenuSectionItemTitleComponent,
		MatMenuModule,
	],
	templateUrl: './my-buildings-section.component.html',
	styleUrls: ['./my-buildings-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyBuildingsSectionComponent implements OnInit {
	private readonly navbarMenuService = inject(NavbarMenuService);
	private readonly store$ = inject(Store);
	private readonly uniqueStorageKey = 'my_buildings_section';
	private readonly destroyRef = inject(DestroyRef);
	private readonly featureFlagService = inject(FeatureFlagService);
	private readonly navbarNavigationService = inject(NavbarNavigationService);

	@ViewChild(FollowBuildingsMenuComponent)
	followBuildingsMenuComponent: FollowBuildingsMenuComponent;

	followedBuildings: Signal<{ id: string; name: string }[]> =
		this.store$.selectSignal(getFollowedBuildings);

	myBuildingsMenuItems: Signal<IAuNavbarConfig[]> = computed(() => {
		const buildings = this.followedBuildings();
		if (!buildings) {
			return [];
		}

		return this.prepareMenuItemsFromBuildings(buildings);
	});
	currentBuildingId = this.store$.selectSignal(selectCurrentBuildingId);
	expanded: WritableSignal<boolean> = signal(false);

	ngOnInit(): void {
		const rehydratedSectionData = this.navbarMenuService.getStoredSectionData(
			this.uniqueStorageKey
		);
		this.expanded.set(rehydratedSectionData.isExpanded);
	}

	onFollowBuildingClicked(building: IOption) {
		this.store$.dispatch(new UpdateFollowedBuildings(building));
	}

	unfollowBuilding(buildingId: string) {
		this.store$.dispatch(new UnfollowBuilding(buildingId));
	}

	onSectionExpanded(expanded: boolean): void {
		this.expanded.set(expanded);
		const rehydratedSectionData = this.navbarMenuService.getStoredSectionData(
			this.uniqueStorageKey
		);
		rehydratedSectionData.isExpanded = expanded;
		localStorage.setItem(this.uniqueStorageKey, JSON.stringify(rehydratedSectionData));
	}

	onSectionMenuItemExpand(): void {
		const rehydratedSectionData = this.navbarMenuService.getStoredSectionData(
			this.uniqueStorageKey
		);
		rehydratedSectionData.menuItems = this.myBuildingsMenuItems();
		localStorage.setItem(this.uniqueStorageKey, JSON.stringify(rehydratedSectionData));
	}

	clearChildSearchText() {
		if (this.followBuildingsMenuComponent) {
			// need to delay so that close animation finish and user will not see flickering
			timer(150)
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe(() => {
					this.followBuildingsMenuComponent.triggerClearSearch();
				});
		}
	}

	private prepareMenuItemsFromBuildings(buildings: IOption[]): IAuNavbarConfig[] {
		const enableMessages = this.featureFlagService.isFFEnabled('operational-messages');
		const enableContacts = this.featureFlagService.isFFEnabled('contacts');

		const menuItems = buildings?.map(building => ({
			title: building.name,
			id: building.id,
			svgIcon: 'building',
			routerLink: `my-building/${building.id}`,
			page: false,
			action: {
				svgIcon: 'more_vert',
				methodKey: 'unfollowBuilding',
				className: 'standard-action',
				isProvidedMenuAction: true,
			},
			children: [
				{
					title: 'Twin',
					svgIcon: 'digital-twin-sidebar',
					routerLink: 'digital-twin',
				},
				{
					title: 'Operations',
					svgIcon: 'cmms',
					routerLink: 'cmms',
					className: 'folder',
					page: false,
					children: [
						{
							title: 'Work Orders',
							routerLink: 'work-orders',
							className: 'cmms',
						},
						{
							title: 'Incidents',
							routerLink: 'incidents',
							className: 'cmms',
						},
						{
							title: 'Requests',
							routerLink: 'requests',
							className: 'cmms',
						},
						{
							title: 'Files',
							routerLink: 'files',
							className: 'cmms',
						},
						{
							title: 'Assets',
							routerLink: 'assets',
							className: 'cmms',
						},
						enableMessages && {
							title: 'Messages',
							routerLink: 'messages',
							className: 'cmms',
						},
						enableContacts && {
							title: 'Contacts',
							routerLink: 'contacts',
							className: 'cmms',
						},
					].filter(el => !!el),
				},
				{
					...this.navbarNavigationService.getChildViews(building.id),
				},
			].filter(el => !!el),
		}));
		const rehydratedSectionData = this.navbarMenuService.getStoredSectionData(
			this.uniqueStorageKey
		);
		this.navbarMenuService.applyStoredMenuState(
			menuItems,
			rehydratedSectionData.menuItems
		);
		return menuItems;
	}
}
