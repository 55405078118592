<p class="chat-title">Chats</p>
<button
	class="au-add-fab-button"
	matTooltip="Start a new chat"
	(click)="createNewChat()"
	matRipple
	matRippleColor="rgba(#1c4b31, 0.05)"
>
	<mat-icon class="accent-icon" svgIcon="add">add</mat-icon>
</button>
