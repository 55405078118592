<div class="toaster-container" *ngIf="data.length">
	<!--TODO add different toast elements in the same manner as for notifications list-->
	<div
		class="toast sensor-event"
		*ngFor="let toast of data"
		(click)="readMessage(toast.id)"
		auSwipe
		(swipeMove)="moveToast($event, toast.id)"
		(swipeEnd)="toastSwipeEnd($event)"
	>
		<!--TODO implement showing this element only on hover-->
		<div class="close-toast">
			<mat-icon
				matRipple
				class="close-icon accent-icon"
				(click)="dismissNotification(toast.id)"
			>
				close
			</mat-icon>
		</div>
		<div class="toast-icon sensor-offline" fxLayoutAlign="center center">
			<mat-icon svgIcon="sensor" class="critical-icon"></mat-icon>
		</div>
		<div class="toast-body">
			<h4 class="message">Sensor is {{ toast.status }}</h4>
			<p class="message-object">
				{{ toast.sensorName }}
			</p>
			<p class="object-location">
				{{ toast.location.name }}
			</p>
		</div>
		<div class="time">
			{{ toast.created | dateAgo }}
		</div>
	</div>
</div>
