import {
	Component,
	Inject,
	LOCALE_ID,
	OnDestroy,
	OnInit,
	ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrentUser } from '@auth/state/auth.selectors';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import svgIcons from '@app/configs/svg-icons.config';
import { Router } from '@angular/router';
import config from '@app/configs/au-main-config';
import { environment } from '@env/environment';
import { AuthService } from '@auth/auth.service';
import { AuUtilsFunctions } from '@app/shared';
import { IAuthState } from '@auth/state/auth.reducer';
import { NgxPendoService } from 'ngx-pendo';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IUser } from '@shared/interfaces/user.interface';
import { Intercom } from '@supy-io/ngx-intercom';
import * as Sentry from '@sentry/angular';
import {
	NgxSmartBannerPlatform,
	NgxSmartBannerService,
} from '@netcreaties/ngx-smart-banner';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>();

	constructor(
		@Inject(LOCALE_ID) public locale: string,
		protected ngxPendoService: NgxPendoService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private store: Store<IAuthState>,
		private router: Router,
		private authService: AuthService,
		private intercom: Intercom,
		private ngxSmartBannerService: NgxSmartBannerService,
		private viewContainerRef: ViewContainerRef
	) {
		this.setSvgIconsToMatIconRegistry(svgIcons);
	}

	ngOnInit() {
		if (AuUtilsFunctions.isKeycloakAuth()) {
			this.authService.getAndStoreUserProfile();
		}
		this.store
			.select(getCurrentUser)
			.pipe(
				takeUntil(this.destroy$),
				filter(hasValue => !!hasValue)
			)
			.subscribe((profile: IUser) => {
				this.initializeToolsOnUserLoad(profile);
			});

		// if user get an error and hit F5 we will not enrage him
		if (window?.location?.pathname === '/error_page') {
			this.router.navigate(['/']);
		}
	}
	initializeToolsOnUserLoad(profile) {
		if (environment.production) {
			this.initializePendoAnalytics(profile);
			this.bootIntercom(profile);
		}
		this.addSentryUserInfo(profile);
		this.initSmartBanner();
	}
	setSvgIconsToMatIconRegistry(obj): void {
		for (const k of obj) {
			this.matIconRegistry.addSvgIcon(
				k.name,
				this.domSanitizer.bypassSecurityTrustResourceUrl(k.link)
			);
		}
	}

	initSmartBanner(): void {
		this.ngxSmartBannerService.initialize({
			viewContainerRef: this.viewContainerRef,
			title: 'Autility - Twin In-Use',
			icon: {
				android: '../assets/icons/logo/app-icon.svg',
			},
			buttonUrl: {
				android:
					'https://play.google.com/store/apps/details?id=no.autility.cmmsapp&hl=en',
			},
			author: 'Næringsliv',
			daysReminder: 1,
			daysHidden: 1,
			hideRating: true,
			enabledPlatforms: [NgxSmartBannerPlatform.Android],
			priceSuffix: {
				android: '',
			},
			price: '',
		});
	}

	private initializePendoAnalytics(profile: IUser) {
		/** You can use directives to add pendo id and sections
		 * @example
		 * <div ngx-pendo-section="section">
		 *   <button ngx-pendo-id="click_test">Click Test</button>
		 * </div>
		 */
		this.ngxPendoService.initialize(
			{
				id: profile.psId,
				name: `${profile.firstName} ${profile.lastName}`,
				email: profile.email,
				role: profile.organization.userOrganizationRole,
				environment: environment.env,
			},
			{
				id: profile.organization.psId,
				name: profile.organization.name,
			}
		);
	}
	// This is left until Pendo tests will be complete
	// (after that, we will remove it or leave instead Pendo)

	bootIntercom(profile: IUser): void {
		this.authService.getIntercomKey().subscribe(key => {
			this.intercom.boot({
				app_id: config.portal.intercomId,
				name: profile.firstName + ' ' + profile.lastName,
				email: profile.email,
				user_hash: key,
				user_id: profile.psId,
				company: {
					name: profile.organization.name,
					company_id: profile.organization.psId,
				},
			});
		});
	}

	private addSentryUserInfo({ email, firstName, lastName, organization }: IUser): void {
		Sentry.setUser({
			email,
			name: `${firstName} ${lastName}`,
			organization: organization.name,
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
