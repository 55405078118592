import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { getAuthTranslate } from '@auth/state/auth.selectors';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/configs/reducers.config';
import { ActivatedRoute, Router } from '@angular/router';
import { SendNewPassword } from '@app/auth/state/auth.actions';
import {
	CrossFieldErrorMatcher,
	validatePasswordMatch,
} from '../../validators/password.validator';
import { AuPasswordInput } from '@shared/au-password-input';

@Component({
	selector: 'app-new-password',
	templateUrl: './new-password.component.html',
	styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent extends AuPasswordInput implements OnInit {
	form: UntypedFormGroup;
	items$: any;
	private token: string;
	errorMatcher = new CrossFieldErrorMatcher();

	constructor(
		private fb: UntypedFormBuilder,
		private store$: Store<IAppState>,
		protected activeRoute: ActivatedRoute,
		protected router: Router
	) {
		super();
	}

	ngOnInit() {
		this.items$ = this.store$.select(getAuthTranslate);
		this.token = this.activeRoute.snapshot.queryParams.token;
		this.initForm();
	}

	initForm() {
		this.form = this.fb.group(
			{
				password: [
					'',
					[
						Validators.required,
						this.validateByPattern(new RegExp('^(?=.*[A-Z])'), {
							uppercase: true,
						}),
						this.validateByPattern(new RegExp('^(?=.*[a-z])'), {
							lowercase: true,
						}),
						this.validateByPattern(new RegExp('^(?=.*[0-9])'), {
							number: true,
						}),
						this.validateByPattern(new RegExp('^(?=.{8,})'), {
							length: true,
						}),
					],
				],
				confirmPassword: [''],
			},
			{
				validators: validatePasswordMatch,
			}
		);
	}

	checkError(errorCode: string): boolean {
		return this.password.value?.length ? this.password.hasError(errorCode) : false;
	}

	onSubmit() {
		if (this.form.valid) {
			const val = this.form.value;
			const payload = {
				password: val.password,
				token: this.token,
			};
			if (val.password === val.confirmPassword) {
				this.store$.dispatch(new SendNewPassword(payload));
			}
		}
	}

	get password() {
		return this.form.controls.password;
	}

	get passwordErrorCount() {
		return this.form.controls.password.errors
			? Object.keys(this.form.controls.password.errors).length
			: 0;
	}
}
