<div class="building-selection-title">
	<div class="icon-wrapper">
		<mat-icon svgIcon="robot" />
	</div>
	<h3>Ask your building</h3>
</div>
<p class="building-selection-text body-2 grey">
	Feel free to inquire about any building-related queries through this AI chat. To begin,
	simply specify the building you're interested in.
</p>
<div class="building-selection-selector">
	<mat-form-field hideRequiredMarker>
		<mat-label>Building</mat-label>
		<mat-select
			[formControl]="buildingControl"
			placeholder="Choose building"
			(selectionChange)="onBuildingChange($event)"
		>
			<mat-option *ngFor="let building of buildings$ | async" [value]="building.id">
				<span>{{ building.name }}</span>
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
