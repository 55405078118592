import gql from 'graphql-tag';

export const markNotificationAsReadMutation = gql`
	mutation markNotificationAsReadMutation($pk: UUID!) {
		markNotificationAsRead(pk: $pk) {
			success
			hasUnread
		}
	}
`;

export const markAllNotificationAsReadMutation = gql`
	mutation markAllNotificationAsReadMutation {
		markAllNotificationsAsRead {
			success
		}
	}
`;
