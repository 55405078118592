import { Action, createReducer, on } from '@ngrx/store';
import {
	areaAssetsFilterClose,
	areaAssetsFilterOpen,
	areaAssetsFilterToggle,
	searchAssetsBuildings,
} from '@app/pages/settings/portfolio/state/area-assets.actions';

export const PortfolioFeatureKey = 'assets';

export interface IAssetsBuildingsFilters {
	buildingsId: string[];
	tenants: string[];
	search: string;
}

export interface IAssetState {
	filterOpened: boolean;
	assetsBuildingsFilterState: IAssetsBuildingsFilters;
}

export const initialAssetState: IAssetState = {
	filterOpened: false,
	assetsBuildingsFilterState: {
		buildingsId: null,
		tenants: null,
		search: null,
	},
};

// TODO Upgrade this to use https://v16.ngrx.io/guide/store/feature-creators
const reducer = createReducer(
	initialAssetState,
	on(areaAssetsFilterOpen, state => ({
		...state,
		filterOpened: true,
	})),
	on(areaAssetsFilterClose, state => ({
		...state,
		filterOpened: false,
	})),
	on(areaAssetsFilterToggle, state => ({
		...state,
		filterOpened: !state.filterOpened,
	})),
	on(
		searchAssetsBuildings,
		(state, assetsBuildingsFilterState: IAssetsBuildingsFilters) => {
			return {
				...state,
				assetsBuildingsFilterState,
			};
		}
	)
);

export function assetsReducer(state: IAssetState | undefined, action: Action) {
	return reducer(state, action);
}
