import gql from 'graphql-tag';

export const SelectableOptionsQuery = gql`
	query SelectableOptionsQuery {
		availableVariables
	}
`;

export const SelectableAssigneesQuery = gql`
	query SelectableAssigneesQuery {
		organizations {
			contentType
			id
			psId
			name
			permissionLevel
			logoUrl
			type
			teams {
				teamType
				id
				psId
				name
				contentType
				smallLogoUrl
				permissionLevel
				users {
					contentType
					email
					firstName
					lastName
					phoneNumber
					countryCode
					avatarUrl
					smallAvatarUrl
					id
					psId
					userRole
				}
			}
		}
	}
`;
