import { ChangeDetectionStrategy, Component, inject, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import {
	breadcrumbConfiguration,
	breadcrumbMenuConfiguration,
} from '@layout/state/layout.selectors';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { IAuBreadcrumbMenuConfig } from '@core/au-breadcrumb/interfices/au-breadcrumbs.interface';
import { MatDividerModule } from '@angular/material/divider';

@Component({
	selector: 'au-breadcrumbs',
	standalone: true,
	imports: [
		CommonModule,
		RouterLink,
		MatIconModule,
		MatRippleModule,
		MatMenuModule,
		MatButtonModule,
		MatDividerModule,
	],
	templateUrl: './au-breadcrumb.component.html',
	styleUrls: ['./au-breadcrumb.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuBreadcrumbComponent {
	private readonly store$ = inject(Store);
	breadcrumbs = this.store$.selectSignal(breadcrumbConfiguration);
	menu: Signal<IAuBreadcrumbMenuConfig> = this.store$.selectSignal(
		breadcrumbMenuConfiguration
	);
}
