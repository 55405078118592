import {
	UntypedFormControl,
	UntypedFormGroup,
	FormGroupDirective,
	NgForm,
	ValidatorFn,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');

export const passwordValidator: ValidatorFn = control => {
	const { value } = control;

	if (regex.test(value)) {
		return null;
	}

	return {
		password: true,
	};
};

export function validatePasswordMatch(form: UntypedFormGroup) {
	const condition = form.get('password').value !== form.get('confirmPassword').value;

	return condition ? { passwordsDoNotMatch: true } : null;
}

export class CrossFieldErrorMatcher implements ErrorStateMatcher {
	isErrorState(
		control: UntypedFormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		return control.dirty && form.invalid;
	}
}
