import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '@env/environment';
import { storeFreeze } from 'ngrx-store-freeze';
import { routerReducer } from '@ngrx/router-store';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAppState {}

export const reducers: ActionReducerMap<IAppState> = {
	router: routerReducer,
};

export const metaReducers: MetaReducer<IAppState>[] = !environment.production
	? [storeFreeze]
	: [];
