import { AuthActions, AuthActionTypes } from './auth.actions';
import { IUser } from '@app/shared/interfaces/user.interface';
import config from '@app/configs/au-main-config';

export interface IAuthState {
	errorMessage: string | null;
	currentUser: IUser;
	token: string | null;
	loadAuthTranslate: boolean;
	authTranslate: Record<string, Record<string, string>> | null;
	message: Record<string, unknown> | null;
	pending: boolean;
	resetEmailSent: boolean;
}

export const initialAuthState: IAuthState = {
	errorMessage: null,
	currentUser: null,
	token: null,
	loadAuthTranslate: false,
	authTranslate: null,
	message: null,
	pending: false,
	resetEmailSent: false,
};

export function getAuthState(token): IAuthState {
	if (!token) {
		return initialAuthState;
	}
	const user = JSON.parse(
		localStorage.getItem(config.local_storage.current_user)
	) as IUser;
	const state = { ...initialAuthState };
	state.currentUser = user;
	state.token = token;
	return state;
}

export function getToken(): string | null {
	return localStorage.getItem(config.local_storage.auth_token);
}

export function authReducer(
	state = getAuthState(getToken()),
	action: AuthActions
): IAuthState {
	switch (action.type) {
		case AuthActionTypes.PASSWORD_RESET_EMAIL_SENT: {
			return {
				...state,
				resetEmailSent: true,
			};
		}

		case AuthActionTypes.LOGIN_SUCCESS: {
			localStorage.setItem(config.local_storage.auth_token, action.payload.token);
			return {
				...state,
				token: action.payload.token,
				errorMessage: null,
			};
		}

		case AuthActionTypes.CURRENT_USER_SUCCESS: {
			localStorage.setItem(
				config.local_storage.current_user,
				JSON.stringify(action.payload.user)
			);
			return {
				...state,
				currentUser: action.payload.user,
				errorMessage: null,
			};
		}

		case AuthActionTypes.UPDATE_CURRENT_USER: {
			localStorage.setItem(
				config.local_storage.current_user,
				JSON.stringify(action.payload.user)
			);
			return {
				...state,
				currentUser: action.payload.user,
			};
		}

		case AuthActionTypes.CURRENT_USER_FAILURE: {
			return {
				...state,
				errorMessage: 'Unable to get user data.',
			};
		}

		case AuthActionTypes.SIGNUP_SUCCESS: {
			return {
				...state,
				token: action.payload.token,
				errorMessage: null,
			};
		}

		case AuthActionTypes.SIGNUP_FAILURE: {
			return {
				...state,
				errorMessage: 'That email is already in use.',
			};
		}

		case AuthActionTypes.LOGOUT: {
			return {
				...initialAuthState,
				authTranslate: state.authTranslate,
				loadAuthTranslate: state.loadAuthTranslate,
			};
		}

		case AuthActionTypes.LOAD_AUTH_TRANSLATE_SUCCESS: {
			return {
				...state,
				loadAuthTranslate: true,
				authTranslate: action.payload.data,
				errorMessage: null,
			};
		}

		case AuthActionTypes.LOAD_AUTH_TRANSLATE_FAILURE: {
			return {
				...state,
				errorMessage: 'Not possible to load translate.',
			};
		}

		case AuthActionTypes.UPDATE_FOLLOWED_BUILDINGS_SUCCESS: {
			return {
				...state,
				currentUser: {
					...state.currentUser,
					followingBuildings: action.payload,
				},
			};
		}

		case AuthActionTypes.UNFOLLOW_BUILDING_SUCCESS: {
			return {
				...state,
				currentUser: {
					...state.currentUser,
					followingBuildings: action.payload,
				},
			};
		}

		default:
			return state;
	}
}
