<div class="au-nav-section">
	<menu-section-item-title
		title="My Buildings"
		[isActionPresent]="true"
		[isMenuItemsAvailable]="!!myBuildingsMenuItems().length"
		[expanded]="expanded()"
		(sectionExpanded)="onSectionExpanded($event)"
	>
		<mat-icon
			actionBtn
			svgIcon="add"
			class="menu-item-action standard-action"
			[matMenuTriggerFor]="actionMenu"
		/>
	</menu-section-item-title>
	<ng-container *ngIf="expanded()">
		<div class="menu-item-wrapper" *ngFor="let menuItem of myBuildingsMenuItems()">
			<menu-item-content
				[menuItem]="menuItem"
				[level]="0"
				(expandedChange)="onSectionMenuItemExpand()"
			>
				<div menuContent class="leave-building-menu">
					<div class="menu-option" (click)="unfollowBuilding(menuItem.id)">
						Leave building ...
					</div>
				</div>
			</menu-item-content>
		</div>
	</ng-container>

	<mat-menu
		#actionMenu="matMenu"
		xPosition="after"
		class="follow-buildings-menu"
		(closed)="clearChildSearchText()"
	>
		<follow-buildings-menu
			[followedBuildings]="followedBuildings()"
			(followBuildingClicked)="onFollowBuildingClicked($event)"
		/>
	</mat-menu>
</div>
