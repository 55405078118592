<mat-card appearance="outlined" fxFlex fxLayout="column" fxLayoutAlign="start center">
	<mat-card-header fxLayout="column">
		<mat-icon class="logo" svgIcon="logo" fxFlexOrder="0"></mat-icon>
		<mat-card-title fxFlexOrder="1">
			<h2>Please set your password</h2>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<form [formGroup]="form" (ngSubmit)="form.valid && onSubmit()">
			<mat-form-field hideRequiredMarker>
				<input
					#newPassword
					autocomplete="new_password"
					autofocus
					type="password"
					matInput
					placeholder="Password"
					formControlName="password"
				/>
				<mat-icon
					class="pointer"
					(click)="toggleInputType($event, newPassword)"
					matSuffix
					[svgIcon]="getInputIcon(newPassword.type)"
				></mat-icon>
				<mat-error *ngIf="passwordRequirementsNotMet">
					Password does not fit requirements
				</mat-error>
			</mat-form-field>

			<mat-form-field hideRequiredMarker>
				<input
					#passwordRepeat
					autocomplete="new_password_repeat"
					type="password"
					matInput
					placeholder="Repeat Password"
					formControlName="confirmPassword"
				/>
				<mat-icon
					class="pointer"
					(click)="toggleInputType($event, passwordRepeat)"
					matSuffix
					[svgIcon]="getInputIcon(passwordRepeat.type)"
				></mat-icon>
				<mat-error *ngIf="confirmPassword.hasError('mustMatch')">
					Passwords are not equal
				</mat-error>
			</mat-form-field>

			<button mat-raised-button color="primary" type="submit" mat-button>
				Set Password
			</button>
		</form>
		<div>
			<p>
				Password must contain: At least 8 characters; At least 1 uppercase letter (A-Z);
				At least 1 lower case letter (a-z); At least 1 number (0...9)
			</p>
		</div>
	</mat-card-content>
</mat-card>
