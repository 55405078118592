import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import packageJson from '../package.json';
import { EnvironmentNames } from '@app/shared';

const getFriendlyEnvName = (envName: EnvironmentNames) => {
	if (envName === EnvironmentNames.prod) {
		return 'production'; // matches previous prod env name in sentry
	}
	return envName;
};

Sentry.init({
	dsn: 'https://f3bb03fa45a33f9d5aa31f878a817021@o4505781938290689.ingest.sentry.io/4505781941174272',
	environment: getFriendlyEnvName(environment.env),
	release: packageJson.version,
	integrations: [
		Sentry.browserTracingIntegration(),
		// Sentry.replayIntegration(), // Replay causes large delays on render, needs more configuration before reenabling
	],
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['https://qa.autility.no/'],
	// Performance Monitoring
	tracesSampleRate: 0.1, // 1 - Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	// eslint-disable-next-line no-console
	.catch(err => console.log(err));
