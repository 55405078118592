import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuUserExtraFeaturesService } from '@core/services/au-user-extra-features.service';
import { isAiChatOpened } from '@app/layout/state/layout.selectors';
import { openAiChat } from '@app/layout/state/layout.actions';

/**
 * May the God bless that person who will try to make it standalone
 * P.S. You'll face pain and suffering with totally unuseful stacktrace of error that
 * is generally produced because of some module imports especially au-notifications
 * If it will tell you about cmms-table-configs - it's a lie >_<
 **/
@Component({
	selector: 'topbar-action-buttons',
	templateUrl: './topbar-action-buttons.component.html',
	styleUrls: ['./topbar-action-buttons.component.scss'],
})
export class TopbarActionButtonsComponent {
	private readonly store$ = inject(Store);
	private readonly userExtraFeaturesService = inject(AuUserExtraFeaturesService);

	isAiChatEnabled = this.userExtraFeaturesService.isAiChatEnabled;

	isAiChatOpened = this.store$.selectSignal(isAiChatOpened);

	openAiChat() {
		this.store$.dispatch(openAiChat());
	}
}
