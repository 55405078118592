import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuRightSidebarService } from '@core/services/au-right-sidebar.service';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { closeAiChat } from '@layout/state/layout.actions';
import { ChatListComponent } from '@app/ai-chat/chat-list/chat-list.component';
import { ChatConversationAreaComponent } from '@app/ai-chat/chat-conversation-area/chat-conversation-area.component';
import { AiChatService } from '@app/ai-chat/services/ai-chat.service';

@Component({
	selector: 'ai-chat',
	standalone: true,
	imports: [CommonModule, ChatListComponent, ChatConversationAreaComponent],
	templateUrl: './ai-chat.component.html',
	styleUrls: ['./ai-chat.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [AiChatService],
})
export class AiChatComponent implements OnInit, OnDestroy {
	private readonly destroyRef = inject(DestroyRef);
	private readonly sidebarService = inject(AuRightSidebarService);
	private readonly store$ = inject(Store);

	isFullscreen$: Observable<boolean>;

	ngOnInit() {
		this.isFullscreen$ = this.sidebarService.sidebarFullscreen$;
		this.trackSidebarCloseAction();
	}

	private trackSidebarCloseAction() {
		this.sidebarService.sidebarIsClosing$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.sidebarService.closeAndClearPortal();
				this.store$.dispatch(closeAiChat());
			});
	}

	ngOnDestroy() {
		this.store$.dispatch(closeAiChat());
	}
}
