import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'au-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {
	@Output() policyAccepted = new EventEmitter<void>();

	accept() {
		this.policyAccepted.emit();
	}
}
