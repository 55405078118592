import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyPolicyComponent } from '@core/privacy-policy/privacy-policy.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [PrivacyPolicyComponent],
	imports: [CommonModule, MatButtonModule],
	exports: [PrivacyPolicyComponent],
})
export class PrivacyPolicyModule {}
