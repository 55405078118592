import { Injectable } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';

const localStorageKey = 'navbarSize';

@Injectable({ providedIn: 'root' })
export class NavbarSizeService {
	private newWidthSubject = new Subject<number>();

	constructor() {
		this.newWidthSubject.pipe(debounceTime(500)).subscribe(newWidth => {
			localStorage.setItem(localStorageKey, `${newWidth}`);
		});
	}
	getStoredWidth() {
		return localStorage.getItem(localStorageKey);
	}

	setStoredWidth(newWidth: number) {
		this.newWidthSubject.next(newWidth);
	}
}
