import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { InMemoryCache } from '@apollo/client/core';

const defaultHttpUri = environment.graphql; // <-- add the URL of the GraphQL server here
const contextHttpUri = environment.contextGraphql;
const cmmsHttpUri = environment.cmmsGraphql;
const federationHttpUri = environment.federationGraphql;
const dexterHttpUri = environment.dexterGraphql;

export function createDefaultApollo(httpLink: HttpLink) {
	// Create an http link:
	const http = httpLink.create({
		uri: defaultHttpUri,
		extractFiles: body => extractFiles(body, isExtractableFile),
	});
	return {
		link: http,
		cache: new InMemoryCache(),
	};
}

/**
 * To use in app as:
 * ```
 * apollo.use('context').query({...});
 *
 * ```
 */
export function createNamedApollo(httpLink: HttpLink) {
	return {
		contextualization: {
			name: 'context',
			link: httpLink.create({
				uri: contextHttpUri,
				extractFiles: body => extractFiles(body, isExtractableFile),
			}),
			cache: new InMemoryCache(),
		},
		cmms: {
			name: 'cmms',
			link: httpLink.create({
				uri: cmmsHttpUri,
				extractFiles: body => extractFiles(body, isExtractableFile),
			}),
			cache: new InMemoryCache(),
		},
		federation: {
			name: 'federation',
			link: httpLink.create({
				uri: federationHttpUri,
				extractFiles: body => extractFiles(body, isExtractableFile),
			}),
			cache: new InMemoryCache({
				possibleTypes: {
					ExternalUserType: ['ProfileType', 'OrganizationTeamType'],
					DigitalTwinIfcModelInterface: [
						'DigitalTwinIfcModelType',
						'DigitalTwinPlanModelType',
					],
				},
			}),
		},
		dexter: {
			name: 'dexter',
			link: httpLink.create({
				uri: dexterHttpUri,
				extractFiles: body => extractFiles(body, isExtractableFile),
			}),
			cache: new InMemoryCache(),
		},
	};
}
@NgModule({
	exports: [ApolloModule],
	providers: [
		{
			provide: APOLLO_OPTIONS,
			deps: [HttpLink],
			useFactory: createDefaultApollo,
		},
		{
			provide: APOLLO_NAMED_OPTIONS,
			deps: [HttpLink],
			useFactory: createNamedApollo,
		},
	],
})
export class GraphQLModule {}
