import { openWorkOrderWorkspaceFromOutside } from '@app/pages/cmms/components/work-order/state/work-order.actions';
import { openIncidentWorkspaceFromOutside } from '@app/pages/cmms/components/incident/state/incidents.actions';
import { openRequestWorkspaceFromOutside } from '@app/pages/cmms/components/request/state/requests.action';

export const NOTIFICATION_OBJECT_CONFIG = {
	work_order: {
		path: '/cmms/work-orders',
		actionEvent: openWorkOrderWorkspaceFromOutside,
	},
	incident: {
		path: '/cmms/incidents',
		actionEvent: openIncidentWorkspaceFromOutside,
	},
	request: {
		path: '/cmms/requests',
		actionEvent: openRequestWorkspaceFromOutside,
	},
};
