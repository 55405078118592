import { createAction, props } from '@ngrx/store';
import { EntityMap, Update } from '@ngrx/entity';

import { INotification } from '@core/notifications/interfaces/notifications.interfaces';
import { PaginationPageInfo } from '@shared/ui-components/au-paginator/pagination-page.info';

export const loadNotifications = createAction('[Notifications] Load Notifications');
export const loadNotificationsSuccess = createAction(
	'[Notifications] Load Notifications success',
	props<{
		notifications: INotification[];
		notificationsPagination: { pageInfo: PaginationPageInfo };
		hasUnreadNotifications: boolean;
	}>()
);
export const loadNotificationsFailure = createAction(
	'[Notifications] Load Notifications failure'
);

export const loadMoreNotifications = createAction(
	'[Notifications] Load More Notifications'
);
export const loadMoreNotificationsSuccess = createAction(
	'[Notifications] Load More Notifications success',
	props<{
		notifications: INotification[];
		notificationsPagination: { pageInfo: PaginationPageInfo };
		hasUnreadNotifications: boolean;
	}>()
);
export const loadMoreNotificationsFailure = createAction(
	'[Notifications] Load More Notifications Failure'
);

export const markNotificationAsRead = createAction(
	'[Notifications] Mark Notification As Read',
	props<{ pk: string }>()
);

export const markAllNotificationAsRead = createAction(
	'[Notifications] Mark All Notifications As Read'
);

export const notificationsLoaded = createAction(
	'[Notifications] Notifications Loaded',
	props<{
		notifications: INotification[];
		notificationsPagination: { pageInfo: PaginationPageInfo };
		hasUnreadNotifications: boolean;
	}>()
);

export const mapNotifications = createAction(
	'[Notifications] Map Notifications',
	props<{ mapper: EntityMap<INotification> }>()
);

export const addNotification = createAction(
	'[Notifications] Add Notification',
	props<{ notification: INotification }>()
);

export const upsertNotification = createAction(
	'[Notifications] Upsert Notification',
	props<{ notification: INotification }>()
);

export const addNotifications = createAction(
	'[Notifications] Add Notifications',
	props<{ notifications: INotification[] }>()
);

export const upsertNotifications = createAction(
	'[Notifications] Upsert Notifications',
	props<{ notifications: INotification[] }>()
);

export const updateNotification = createAction(
	'[Notifications] Update Notification',
	props<{ notification: Update<INotification> }>()
);

export const updateNotificationWithReadStatus = createAction(
	'[Notifications] Update Notification and set unreadMsgLeft',
	props<{ notification: Update<INotification>; unreadMsgLeft: boolean }>()
);

export const updateNotifications = createAction(
	'[Notifications] Update Notifications',
	props<{ notifications: Update<INotification>[] }>()
);

export const deleteNotification = createAction(
	'[Notifications] Delete Notification',
	props<{ pk: string }>()
);

export const deleteNotifications = createAction(
	'[Notifications] Delete Notifications',
	props<{ pks: string[] }>()
);

export const clearNotifications = createAction('[Notifications] Clear Notifications');
