import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { IAuNavbarConfig } from '@app/configs/au-navbar.config';
import { NavbarMenuService } from '@layout/au-nav-sidebar/navbar-menu.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MenuItemContentComponent } from '@layout/au-nav-sidebar/navbar-menu-items/menu-item-content/menu-item-content.component';
import { MenuSectionItemTitleComponent } from '@layout/au-nav-sidebar/navbar-menu-items/menu-section-item-title/menu-section-item-title.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MyBuildingsSectionComponent } from '@layout/au-nav-sidebar/navbar-menu-items/my-buildings-section/my-buildings-section.component';
import { environment } from '@env/environment';
import { AuUserExtraFeaturesService } from '@app/core/services/au-user-extra-features.service';

@Component({
	selector: 'navbar-menu-items',
	templateUrl: './navbar-menu-items.component.html',
	styleUrls: ['./navbar-menu-items.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		RouterModule,
		MenuItemContentComponent,
		MenuSectionItemTitleComponent,
		MatMenuModule,
		MatCheckboxModule,
		MyBuildingsSectionComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarMenuItemsComponent implements OnInit {
	private readonly navbarMenuService = inject(NavbarMenuService);
	private readonly auUserExtraFeaturesService = inject(AuUserExtraFeaturesService);

	@Input() menuItems: IAuNavbarConfig[] = [];
	@Input() isSettingsRoute!: boolean;

	ngOnInit() {
		this.rehydrateMenuState();
	}

	onExpandToggle() {
		this.navbarMenuService.storeMenuState(this.menuItems);
	}

	isMenuItemVisible(menuItem: IAuNavbarConfig): boolean {
		if (menuItem.title === 'Views') {
			return this.isViewExtraFlagEnabled;
		}
		return !menuItem.hideForEnvironment?.includes(environment.env);
	}

	get isViewExtraFlagEnabled(): boolean {
		return this.auUserExtraFeaturesService.isViewsEnabled();
	}

	private rehydrateMenuState() {
		const storedMenuState = this.navbarMenuService.getStoredMenuState();

		if (storedMenuState.length) {
			this.navbarMenuService.applyStoredMenuState(this.menuItems, storedMenuState);
		}
	}
}
