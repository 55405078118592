<div class="placeholder-wrapper">
	<div class="error-placeholder">
		<div class="robot-placeholder m-b-sm">
			<img [src]="contentConfig.placeholderPath" alt="maskot robot with error" />
		</div>
		<h2 class="title">{{ contentConfig.title }}</h2>
		<p>{{ contentConfig.descriptionFirstLine }}</p>
		<p>{{ contentConfig.descriptionSecondLine }}</p>
		<p>{{ contentConfig.descriptionThirdLine }}</p>
	</div>
</div>
