import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import {
	INotificationsState,
	notificationsReducer,
} from '@core/notifications/state/notifications.reducer';
import {
	AddBuildingToSelectableOption,
	AddItemToNavigationHistory,
	AssigneeListLoaded,
	ClearRightSidebarHisory,
	ClearRightSidebarInitialComponent,
	closeAiChat,
	LoadAssignees,
	LoadSelectableOptions,
	NavSidebarExpanded,
	NavSidebarCollapsed,
	openAiChat,
	SelectableAssigneesFailedToLoad,
	SelectableOptionsLoaded,
	SetAttachFormValue,
	SetLinkedObjects,
	SetNavigationHistory,
	SetRightSidebarComponentContext,
	SetRightSidebarInitialComponent,
	UpdateBuildingsInSelectableOption,
	setAuBreadcrumb,
	clearAuBreadcrumb,
	setAuBreadcrumbMenu,
} from '@layout/state/layout.actions';
import { ISelectableOptions } from '@app/shared/interfaces/selectable-options.interfaces';
import { ISidebarConfig } from '@layout/au-right-sidebar/right-sidebar.interfaces';
import { CmmsPages } from '@app/pages/cmms/state/cmms.reducer';
import { TableType } from '@app/pages/cmms/types';
import { RightSidebarAliasesEnum } from '@layout/au-right-sidebar/au-right-sidebar-component-aliases';
import {
	IAuBreadcrumbMenuConfig,
	IAuBreadcrumbsConfig,
} from '@core/au-breadcrumb/interfices/au-breadcrumbs.interface';

export const layoutFeatureKey = 'layout';

export type EntityType = 'WO' | 'REQUEST' | 'INCIDENTS' | 'FILE' | 'ASSET';
export type EntityActionType = 'NEW' | 'ATTACH' | 'EDIT' | 'COPY';

export interface IWorkspaceComponentContext {
	tableTypeForExistingEntity: TableType;
	actionType?: EntityActionType | null;
	currentEntityForEdit?: any;
	editEntityType: EntityType;
	selectedObjects: any; // Value for bulk linking
}

export interface IRightSidebarNavigation {
	initialComponent: RightSidebarAliasesEnum | null;
	initialPage: CmmsPages | null;
	config: Partial<ISidebarConfig> | null;
	formValue?: { [key: string]: any } | null;
	componentContext: Partial<IWorkspaceComponentContext>;
	history: { config: Partial<ISidebarConfig>; component: CmmsPages }[];
}

export interface ILayoutState {
	navSidebarCollapsed: boolean;
	loadingData: boolean;
	selectableOptions: ISelectableOptions;
	assigneeListRequestOngoing: boolean;
	rightSideBar: IRightSidebarNavigation;
	aiChatOpened: boolean;
	breadcrumbConfiguration: IAuBreadcrumbsConfig[] | [];
	breadcrumbMenuConfig: IAuBreadcrumbMenuConfig;
}

export const initialLayoutState: ILayoutState = {
	navSidebarCollapsed: false,
	loadingData: false,
	selectableOptions: null,
	rightSideBar: {
		initialComponent: null,
		initialPage: null,
		config: null,
		formValue: null,
		history: [],
		componentContext: null,
	},
	assigneeListRequestOngoing: false,
	aiChatOpened: false,
	breadcrumbConfiguration: [],
	breadcrumbMenuConfig: null,
};

const layoutReducer = createReducer(
	initialLayoutState,
	on(NavSidebarExpanded, state => ({
		...state,
		navSidebarCollapsed: false,
	})),
	on(NavSidebarCollapsed, state => ({
		...state,
		navSidebarCollapsed: true,
	})),
	on(LoadSelectableOptions, state => {
		return {
			...state,
			assigneeListRequestOngoing: true,
		};
	}),
	on(SelectableOptionsLoaded, (state, { selectableOptions }) => {
		return {
			...state,
			selectableOptions: {
				...state.selectableOptions,
				...selectableOptions,
			},
			assigneeListRequestOngoing: false,
		};
	}),
	on(LoadAssignees, state => {
		return {
			...state,
			assigneeListRequestOngoing: true,
		};
	}),
	on(AssigneeListLoaded, (state, { assignees }) => {
		return {
			...state,
			selectableOptions: {
				...state.selectableOptions,
				assignees,
			},
			assigneeListRequestOngoing: false,
		};
	}),
	on(SelectableAssigneesFailedToLoad, state => {
		return {
			...state,
			assigneeListRequestOngoing: false,
		};
	}),
	on(AddBuildingToSelectableOption, (state, { building }) => {
		const buildings = state.selectableOptions?.buildings;
		return {
			...state,
			selectableOptions: {
				...state.selectableOptions,
				buildings: buildings ? [building, ...buildings] : [building],
			},
		};
	}),
	on(UpdateBuildingsInSelectableOption, (state, { building }) => {
		// eslint-disable-next-line no-unsafe-optional-chaining
		const buildings = [...state.selectableOptions?.buildings];
		const index = buildings.findIndex(b => b.id === building.id);
		buildings[index] = building;

		return {
			...state,
			selectableOptions: {
				...state.selectableOptions,
				buildings: [...buildings],
			},
		};
	}),
	// Right sidebar action
	on(ClearRightSidebarHisory, state => {
		return {
			...state,
			rightSideBar: {
				initialComponent: null,
				config: null,
				formValue: null,
				history: [],
				initialPage: null,
				componentContext: null,
			},
		};
	}),
	// TODO this action should be removed in apply navigation ticket
	on(ClearRightSidebarInitialComponent, state => {
		return {
			...state,
			rightSideBar: {
				initialComponent: null,
				config: null,
				formValue: null,
				history: [],
				initialPage: null,
				componentContext: null,
			},
		};
	}),
	on(SetRightSidebarInitialComponent, (state, { config }) => {
		return {
			...state,
			rightSideBar: {
				...state.rightSideBar,
				...config,
			},
		};
	}),
	on(SetNavigationHistory, (state, { history }) => {
		return {
			...state,
			rightSideBar: {
				...state.rightSideBar,
				history,
			},
		};
	}),
	on(AddItemToNavigationHistory, (state, { config, component }) => {
		return {
			...state,
			rightSideBar: {
				...state.rightSideBar,
				history: [...state.rightSideBar.history, { config, component }],
			},
		};
	}),
	on(SetRightSidebarComponentContext, (state, { componentContext }) => {
		return {
			...state,
			rightSideBar: {
				...state.rightSideBar,
				componentContext: {
					...state.rightSideBar.componentContext,
					...componentContext,
				},
			},
		};
	}),
	on(SetAttachFormValue, (state, { formValue }) => {
		return {
			...state,
			rightSideBar: {
				...state.rightSideBar,
				formValue: {
					...state.rightSideBar.formValue,
					...formValue,
				},
			},
		};
	}),
	on(SetLinkedObjects, (state, { selectedObjects }) => {
		return {
			...state,
			rightSideBar: {
				...state.rightSideBar,
				selectedObjects,
			},
		};
	}),
	// end right sidebar action
	on(openAiChat, state => ({
		...state,
		aiChatOpened: true,
	})),
	on(closeAiChat, state => ({
		...state,
		aiChatOpened: false,
	})),
	on(setAuBreadcrumb, (state, { configuration }) => ({
		...state,
		breadcrumbConfiguration: configuration,
	})),
	on(setAuBreadcrumbMenu, (state, { breadcrumbMenuConfig }) => ({
		...state,
		breadcrumbMenuConfig,
	})),
	on(clearAuBreadcrumb, state => ({
		...state,
		breadcrumbConfiguration: [],
		breadcrumbMenuConfig: null,
	}))
);

interface ILayoutStateReducers {
	main: ILayoutState;
	notifications: INotificationsState;
}

export const layoutReducers: ActionReducerMap<ILayoutStateReducers> = {
	main: layoutReducer,
	notifications: notificationsReducer,
};
