import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@app/configs/reducers.config';
import { isLoggedIn } from '@auth/state/auth.selectors';
import { map } from 'rxjs/operators';
import config from '@app/configs/au-main-config';

@Injectable({
	providedIn: 'root',
})
export class GuestGuard {
	constructor(
		private store$: Store<IAppState>,
		private router: Router
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		return this.store$.pipe(
			select(isLoggedIn),
			map(loggedIn => {
				// @ts-ignore
				if (loggedIn && state.url.indexOf(config.auRoutes.passwordReset.link) === -1) {
					this.router.navigate([config.auRoutes.dashboard.link]);
					return false;
				}
				return true;
			})
		);
	}
}
