import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { INotificationsState } from '@core/notifications/state/notifications.reducer';
import {
	allNotifications,
	failToFetchMessage,
	failToFetchMoreNotifications,
	hasNewNotifications,
	moreNotificationsIsLoading,
	notificationsIsLoading,
} from '@core/notifications/state/notifications.selectors';
import { INotification } from '@core/notifications/interfaces/notifications.interfaces';
import { takeUntil } from 'rxjs/operators';
import {
	loadMoreNotifications,
	markAllNotificationAsRead,
} from '@core/notifications/state/notifications.actions';

@Component({
	selector: 'au-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit, OnDestroy {
	notificationsIsLoading$ = this.store$.select(notificationsIsLoading);
	notificationMessages: INotification[] = [];
	unreadMessages$ = this.store$.select(hasNewNotifications);
	failToFetchMessage$ = this.store$.select(failToFetchMessage);
	failToFetchMoreNotifications$ = this.store$.select(failToFetchMoreNotifications);
	lastItemPk: string;
	loadingMoreNotifications$ = this.store$.select(moreNotificationsIsLoading);

	private destroy$ = new Subject<void>();

	constructor(private store$: Store<INotificationsState>) {}

	ngOnInit() {
		this.store$
			.select(allNotifications)
			.pipe(takeUntil(this.destroy$))
			.subscribe(notifications => {
				this.notificationMessages = [...notifications];
				this.lastItemPk = notifications[notifications.length - 1]?.pk;
			});
	}

	markAsRead(evt: Event) {
		this.store$.dispatch(markAllNotificationAsRead());
	}

	lastItemScrolledIntoView($event: boolean): void {
		if ($event) {
			this.store$
				.select(moreNotificationsIsLoading)
				.pipe(takeUntil(this.destroy$))
				.subscribe(isLoading => {
					if (!isLoading) {
						this.loadMoreNotifications();
					}
				});
		}
	}

	loadMoreNotifications() {
		this.store$.dispatch(loadMoreNotifications());
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
