<div class="chat-input-field-container">
	<textarea
		#messageInput
		class="ai-chat-message-input"
		rows="1"
		(input)="adjustRows(messageInput)"
		placeholder="Ask me anything"
		[formControl]="formControl"
		(keydown)="trackHotKeys($event)"
	></textarea>
	<button class="ai-chat-send-button" (click)="submitRequestToAiChat()">
		<mat-icon svgIcon="envelop" class="send-icon" [class.active]="formControl.valid" />
	</button>
</div>
