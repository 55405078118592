import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IAiChatMessage } from '@app/ai-chat/interfaces/ai-chat-message.interface';
import { AuRoundAvatarModule } from '@shared/ui-components';
import { IUser } from '@shared/interfaces/user.interface';

@Component({
	selector: 'user-typed-message',
	standalone: true,
	imports: [CommonModule, AuRoundAvatarModule],
	templateUrl: './user-typed-message.component.html',
	styleUrls: ['./user-typed-message.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTypedMessageComponent {
	@Input() message: IAiChatMessage;
	@Input() avatarData: IUser;
}
