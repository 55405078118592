import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	loadMoreNotifications,
	loadMoreNotificationsFailure,
	loadMoreNotificationsSuccess,
	loadNotifications,
	loadNotificationsFailure,
	loadNotificationsSuccess,
	mapNotifications,
	markAllNotificationAsRead,
	markNotificationAsRead,
	notificationsLoaded,
	updateNotificationWithReadStatus,
} from '@core/notifications/state/notifications.actions';
import { EMPTY, mergeMap, of } from 'rxjs';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
import { AuErrorAdapter } from '@app/shared';
import { AuNotificationsService } from '@core/services/au-notifications.service';
import { INotificationsState } from '@core/notifications/state/notifications.reducer';
import { notificationsPagination } from '@core/notifications/state/notifications.selectors';

@Injectable()
export class NotificationsEffects {
	constructor(
		private $actions: Actions,
		private notificationsService: AuNotificationsService,
		private store$: Store<INotificationsState>
	) {}

	loadNotifications$ = createEffect(() => {
		return this.$actions.pipe(
			ofType(loadNotifications),
			mergeMap(() => {
				return this.notificationsService.loadNotifications({ first: 10 }).pipe(
					map(({ notifications, notificationsPagination, hasUnreadNotifications }) => {
						return loadNotificationsSuccess({
							notifications,
							notificationsPagination,
							hasUnreadNotifications,
						});
					}),
					catchError(error => {
						AuErrorAdapter.printGqlErrorToConsole(error);
						return of(loadNotificationsFailure());
					})
				);
			})
		);
	});

	loadNotificationsSuccess$ = createEffect(() => {
		return this.$actions.pipe(
			ofType(loadNotificationsSuccess),
			map(({ notifications, notificationsPagination, hasUnreadNotifications }) => {
				return notificationsLoaded({
					notifications,
					notificationsPagination,
					hasUnreadNotifications,
				});
			})
		);
	});

	loadMoreNotifications$ = createEffect(() => {
		return this.$actions.pipe(
			ofType(loadMoreNotifications),
			withLatestFrom(this.store$.select(notificationsPagination)),
			mergeMap(([_, pagination]) => {
				if (!pagination.pageInfo.hasNextPage) {
					return EMPTY;
				}

				return this.notificationsService.loadMoreNotifications(pagination.pageInfo).pipe(
					map(({ notifications, notificationsPagination, hasUnreadNotifications }) => {
						return loadMoreNotificationsSuccess({
							notifications,
							notificationsPagination,
							hasUnreadNotifications,
						});
					}),
					catchError(error => {
						AuErrorAdapter.printGqlErrorToConsole(error);
						return of(loadMoreNotificationsFailure());
					})
				);
			})
		);
	});

	markNotificationAsRead$ = createEffect(() => {
		return this.$actions.pipe(
			ofType(markNotificationAsRead),
			mergeMap(({ pk }) => {
				return this.notificationsService.markNotificationAsRead(pk).pipe(
					map((unreadMsgLeft: boolean) => {
						return updateNotificationWithReadStatus({
							notification: {
								// this is id of entity adapter!!! this code is correct;
								id: pk,
								changes: { isRead: true },
							},
							unreadMsgLeft,
						});
					}),
					catchError(error => {
						AuErrorAdapter.printGqlErrorToConsole(error);
						return EMPTY;
					})
				);
			})
		);
	});

	markAllNotificationAsRead$ = createEffect(() => {
		return this.$actions.pipe(
			ofType(markAllNotificationAsRead),
			mergeMap(() => {
				return this.notificationsService.markAllNotificationsAsRead().pipe(
					map(() => {
						return mapNotifications({
							mapper: notification => ({ ...notification, isRead: true }),
						});
					}),
					catchError(error => {
						AuErrorAdapter.printGqlErrorToConsole(error);
						return EMPTY;
					})
				);
			})
		);
	});
}
