import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { AuNotificationsToasterComponent } from './components/au-notifications-toaster/au-notifications-toaster.component';
import { AuNotificationMessageComponent } from './components/au-notification-message/au-notification-message.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuPipesModule } from '@shared/pipes/au-pipes.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuRoundAvatarModule } from '@shared/ui-components';
import { AuDirectivesModule } from '@shared/directives/au-directives.module';

@NgModule({
	declarations: [
		NotificationsComponent,
		AuNotificationsToasterComponent,
		AuNotificationMessageComponent,
	],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		FlexLayoutModule,
		AuPipesModule,
		MatProgressSpinnerModule,
		AuRoundAvatarModule,
		AuDirectivesModule,
	],
	exports: [
		NotificationsComponent,
		AuNotificationsToasterComponent,
		AuNotificationMessageComponent,
	],
})
export class NotificationsModule {}
