<mat-card appearance="outlined" fxFlex fxLayout="column" fxLayoutAlign="start center">
	<mat-card-header fxLayout="column">
		<mat-icon class="logo" svgIcon="logo"></mat-icon>
		<mat-card-title fxFlexOrder="1">
			<h2>
				{{ (authTranslation$ | async)?.recovery_password.title | titlecase }}
			</h2>
		</mat-card-title>
		<mat-card-subtitle fxFlexOrder="2">{{
			(authTranslation$ | async)?.recovery_password.subtitle
		}}</mat-card-subtitle>
	</mat-card-header>

	<mat-card-content>
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<mat-form-field hideRequiredMarker>
				<input
					autofocus
					type="email"
					matInput
					placeholder="Email"
					formControlName="email"
				/>
				<mat-error>
					<span *ngIf="email.hasError('required')">You must enter a value</span>
					<span *ngIf="email.hasError('email') && email.touched">Not a valid email</span>
				</mat-error>
			</mat-form-field>

			<button
				[disabled]="emailSent$ | async"
				mat-raised-button
				color="primary"
				type="submit"
				mat-button
			>
				<span>{{ (authTranslation$ | async)?.recovery_password.button }}</span>
			</button>

			<a class="routerLink" routerLink="/login">
				<span>{{ (authTranslation$ | async)?.login.button | titlecase }} </span>
			</a>
		</form>
	</mat-card-content>
</mat-card>
