<div
	class="au-nav-section-title"
	[class.has-action-btn]="isActionPresent"
	[class.visible-actions]="!isMenuItemsAvailable"
	[class.always-expanded]="alwaysExpanded"
	(click)="toggleSectionExpand()"
>
	<p>{{ title }}</p>

	<ng-container *ngIf="isMenuItemsAvailable && !alwaysExpanded">
		<mat-icon *ngIf="!expanded" class="child-arrow-icon"> arrow_right </mat-icon>
		<mat-icon *ngIf="expanded" class="child-arrow-icon"> arrow_drop_down </mat-icon>
	</ng-container>

	<div class="action-block" *ngIf="isActionPresent" (click)="$event.stopPropagation()">
		<ng-content select="[actionBtn]" />
	</div>
</div>
