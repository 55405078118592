import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import config from '@app/configs/au-main-config';
import { GuestGuard } from '@auth/guards/guest.guard';
import { AuthComponent } from '@auth/auth.component';
import { LoginComponent } from '@auth/components/login/login.component';
import { SetPasswordComponent } from '@auth/components/set-password/set-password.component';
import { RecoveryPasswordComponent } from '@auth/components/recovery-password/recovery-password.component';
import { NewPasswordComponent } from '@auth/components/new-password/new-password.component';
import { AuUtilsFunctions } from '@app/shared';
import { KeycloakGuestGuard } from '@auth/guards/keycloak.guest.guard';

const authRoutes: Routes = [
	{
		path: '',
		component: AuthComponent,
		canActivate: [getCanActivateGuard()],
		children: [
			{ path: '', redirectTo: `/${config.auRoutes.login.link}`, pathMatch: 'full' },
			{ path: `${config.auRoutes.login.link}`, component: LoginComponent },
			{
				path: `${config.auRoutes.recoveryPassword.link}`,
				component: RecoveryPasswordComponent,
			},
			{
				path: `${config.auRoutes.passwordReset.link}`,
				component: NewPasswordComponent,
			},
			{
				path: `${config.auRoutes.setPassword.link}`,
				component: SetPasswordComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(authRoutes)],
	exports: [RouterModule],
})
export class AuthRoutingModule {}

function getCanActivateGuard() {
	if (AuUtilsFunctions.isAutilityAuth()) {
		return GuestGuard;
	} else if (AuUtilsFunctions.isKeycloakAuth()) {
		return KeycloakGuestGuard;
	}
}
