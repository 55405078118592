import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { AuthService } from '@auth/auth.service';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import config from '@app/configs/au-main-config';
import { Store } from '@ngrx/store';
import { IAuthState } from '@auth/state/auth.reducer';
import { LogOut } from '@auth/state/auth.actions';
import { AuUtilsFunctions } from '@app/shared';
import { KeycloakService } from 'keycloak-angular';
import { IconSnackBarService } from '@core/services/icon-snack-bar.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	escapedUrls = new Set([
		config.auRoutes.login.link,
		config.auRoutes.passwordReset.link,
		config.auRoutes.recoveryPassword.link,
		config.auRoutes.errorPage.link,
		config.auRoutes.setPassword.link,
	]);

	constructor(
		private router: Router,
		private authService: AuthService,
		private store$: Store<IAuthState>,
		private notify: IconSnackBarService,
		private keycloakService: KeycloakService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(err => {
				const url = this.router.url ? this.router.url.replace('/', '').split('?')[0] : '';
				if (err.status === 401) {
					// auto logout if 401 response returned from api
					// only do the navigate when it's not a login form or else we will have refresh
					if (url && !this.escapedUrls.has(url)) {
						if (AuUtilsFunctions.isAutilityAuth()) {
							this.store$.dispatch(new LogOut());
						} else if (AuUtilsFunctions.isKeycloakAuth()) {
							this.keycloakService.logout();
						}

						this.notify.error('Your request was unauthorized');
					}
					this.authService.clearToken();
				} else if (err.status === 500 || err.status === 502) {
					if (
						url &&
						!this.escapedUrls.has(url) &&
						this.operationNameIsNot(request, 'SelectableOptionsQuery')
					) {
						this.router.navigate([config.auRoutes.errorPage.link]);
					}
				}
				return throwError(err);
			})
		);
	}

	operationNameIsNot(request: HttpRequest<any>, operationName: string): boolean {
		return request.body?.operationName !== operationName;
	}
}
