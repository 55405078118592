import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import packageJson from '../../../../package.json';
import {
	BehaviorSubject,
	catchError,
	filter,
	interval,
	map,
	merge,
	of,
	switchMap,
	timer,
} from 'rxjs';
import { environment } from '@env/environment';
import { EnvironmentNames } from '@app/shared';

const FIRST_CHECK_TIME_MS = 1_000;
const CHECK_INTERVAL_TIME_MS = 30_000;

export type VersionCheckResult = {
	current: string;
	available: string;
};

@Injectable({ providedIn: 'root' })
export class VersionCheckService {
	private http = inject(HttpClient);

	private readonly newVersionSubject = new BehaviorSubject<VersionCheckResult>(undefined);

	public readonly newVersionAvailable$ = this.newVersionSubject.pipe(
		filter(v => v != null)
	);
	public readonly newVersionAvailable = signal<string>(undefined);

	constructor() {
		this.setupVersionCheck();
	}

	private setupVersionCheck() {
		if (environment.env === EnvironmentNames.local) {
			// disable version check for local environment
			return;
		}
		const usingVersion = packageJson.version;

		const trigger = merge(timer(FIRST_CHECK_TIME_MS), interval(CHECK_INTERVAL_TIME_MS));

		trigger
			.pipe(
				switchMap(() => {
					return this.http
						.get<{
							version: string;
						}>(`./assets/version.json?cache_bust=${new Date().getTime()}`)
						.pipe(
							catchError(() => {
								return of({ version: usingVersion });
							})
						);
				}),
				map(({ version }) => version),
				filter(availableVersion => availableVersion !== usingVersion)
			)
			.subscribe(availableVersion => {
				const update: VersionCheckResult = {
					current: usingVersion,
					available: availableVersion,
				};
				this.newVersionSubject.next(update);
				this.newVersionAvailable.set(availableVersion);
			});
	}
}
