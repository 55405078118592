import { Component, OnInit } from '@angular/core';
import { AuPasswordInput } from '@shared/au-password-input';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAuthState } from '@auth/state/auth.reducer';
import { SetUserPassword } from '@auth/state/auth.actions';

@Component({
	selector: 'au-set-password',
	templateUrl: './set-password.component.html',
	styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent extends AuPasswordInput implements OnInit {
	form: UntypedFormGroup;
	private token: string;

	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private store$: Store<IAuthState>
	) {
		super();

		this.initForm();
	}

	ngOnInit() {
		this.token = this.route.snapshot.queryParams.token;
	}

	initForm() {
		this.form = this.fb.group(
			{
				password: [
					'',
					[
						Validators.required,
						this.validateByPattern(new RegExp('^(?=.*[A-Z])'), {
							uppercase: true,
						}),
						this.validateByPattern(new RegExp('^(?=.*[a-z])'), {
							lowercase: true,
						}),
						this.validateByPattern(new RegExp('^(?=.*[0-9])'), {
							number: true,
						}),
						this.validateByPattern(new RegExp('^(?=.{8,})'), { length: true }),
					],
				],
				confirmPassword: ['', Validators.required],
			},
			{ validator: this.mustMatch('password', 'confirmPassword') }
		);
	}

	onSubmit() {
		this.store$.dispatch(
			new SetUserPassword({
				password: this.form.value.password,
				token: this.token,
			})
		);
	}

	get password() {
		return this.form.controls.password;
	}

	get confirmPassword() {
		return this.form.controls.confirmPassword;
	}

	get passwordRequirementsNotMet(): boolean {
		return (
			this.password.hasError('length') ||
			this.password.hasError('uppercase') ||
			this.password.hasError('lowercase') ||
			this.password.hasError('number')
		);
	}
}
