import { Component } from '@angular/core';
import { NgForOf } from '@angular/common';

@Component({
	selector: 'xmass-lights-animation',
	template: `
		<ul class="lightrope">
			<li *ngFor="let _ of [].constructor(42)"></li>
		</ul>
	`,
	styleUrls: ['./xmass-lights-animation.component.scss'],
	standalone: true,
	imports: [NgForOf],
})
export class XmassLightsAnimationComponent {}
