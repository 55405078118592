import { Component, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AuSwipeDirective, SwipeEvent } from '@shared/directives/au-swipe-directive';

//TODO This is not used now, but left as example of how swipe is used,
// still need to check if it's working as expected. Will be used after subscription is ready
// Although some methods and variables are cleared so need to recheck them
@Component({
	selector: 'au-notifications-toaster',
	templateUrl: './au-notifications-toaster.component.html',
	styleUrls: ['./au-notifications-toaster.component.scss'],
})
export class AuNotificationsToasterComponent implements OnInit, OnDestroy {
	@Input() messageLimit = 4;

	@ViewChild(AuSwipeDirective) swipeElementHost: AuSwipeDirective;

	data = [];
	isMobile = true;

	private destroy$ = new Subject<void>();

	constructor(
		private breakpointObserver: BreakpointObserver,
		private renderer: Renderer2
	) {}

	ngOnInit() {
		this.checkForMobile();

		this.listenForNewNotifications();
	}

	listenForNewNotifications() {}

	checkForMobile() {
		this.breakpointObserver
			.observe(['(max-width: 600px)'])
			.subscribe((state: BreakpointState) => {
				this.isMobile = state.matches;
			});
	}

	dismissNotification(toastId: string): void {
		const index = this.data.findIndex(toast => {
			return toast.id === toastId;
		});
		this.data.splice(index, 1);
	}

	readMessage(toastId: string): void {}

	moveToast(evt: SwipeEvent, toastId: string) {
		if (evt.direction === 'y') {
			return;
		}

		// Add inversion to use directly in styles
		const moveTo = -evt.distance;

		// Bounce effect if swipe to left
		if (moveTo > 100) {
			this.setToastStyle('right', '0px');
			return;
		}

		/**
		 * If swipe to right we have two options
		 * 	1) It's swiped but not too far, so we change opacity and track exact
		 * 	location of toast, until user remove his finger, then we just revert back it's styles
		 * 	2) If it's moved too far to right *hidePointValue - then we just dismiss it
		 */
		if (moveTo < 0) {
			const hidePointValue = 200;

			/**
			 * Formula:
			 * If we have 50px hidePoint then each pixel of moveTo will be 100%/50px = 2% per pixel
			 * So the opacity will be 100% - distance * %perPixel
			 */
			const finalOpacity = (100 - evt.distance * (100 / hidePointValue)) / 100;

			this.setToastStyle('opacity', `${finalOpacity}`);
		}

		if (moveTo < -150) {
			this.setToastClass('remove');
			this.dismissNotification(toastId);
		}

		this.setToastStyle('right', `${moveTo}px`);
	}

	toastSwipeEnd(evt: SwipeEvent) {
		if (evt.direction === 'y') {
			return;
		}

		if (evt.distance < 0) {
			this.setToastStyle('right', '0px');
		} else if (evt.distance >= 0 && evt.distance < 150) {
			this.setToastStyle('opacity', '1');
			this.setToastStyle('right', '0px');
		}
	}

	private setToastStyle(style: string, value: string): void {
		this.renderer.setStyle(this.swipeElementHost.elementRef.nativeElement, style, value);
	}

	private setToastClass(className: string): void {
		this.renderer.addClass(this.swipeElementHost.elementRef.nativeElement, className);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
