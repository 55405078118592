<button
	mat-icon-button
	type="button"
	aria-label="notifications-toggle"
	[matMenuTriggerFor]="notificationsList"
	#notificationsButton="matMenuTrigger"
	[matMenuTriggerRestoreFocus]="false"
>
	<mat-icon
		svgIcon="bell"
		class="accent-icon"
		[class.unread-marker]="unreadMessages$ | async"
	></mat-icon>
</button>

<mat-menu
	#notificationsList="matMenu"
	class="notifications-list-container"
	xPosition="before"
>
	<div class="notifications-menu" (click)="$event.stopPropagation()">
		<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
			<h3>Notifications</h3>
			<a
				class="link-button"
				*ngIf="notificationMessages?.length && unreadMessages$ | async"
				(click)="markAsRead($event)"
				>Mark all as read</a
			>
		</div>

		<div class="notifications-content">
			<div class="spinner-wrapper" *ngIf="notificationsIsLoading$ | async">
				<mat-spinner diameter="40"></mat-spinner>
			</div>

			<ng-container *ngIf="(notificationsIsLoading$ | async) === false">
				<ng-container
					*ngTemplateOutlet="
						notificationMessages?.length
							? notificationsContainer
							: emptyNotificationsContainer
					"
				>
				</ng-container>
			</ng-container>
		</div>
	</div>
</mat-menu>

<!-- Notifications container templates -->
<ng-template #notificationsContainer>
	<div class="notifications-list nice-scroll colored-scroll-track visible-scroll">
		<ng-container *ngFor="let notification of notificationMessages; let last = last">
			<au-notification-message
				auScrollItemVisible
				(itemVisible)="lastItemScrolledIntoView($event)"
				[isTargetItem]="notification.pk === lastItemPk"
				[notification]="notification"
				[isLastItem]="last"
				(click)="notificationsButton.closeMenu()"
			></au-notification-message>
		</ng-container>
		<div
			class="more-notifications-spinner-container"
			*ngIf="loadingMoreNotifications$ | async"
		>
			<div class="spinner-wrapper">
				<mat-spinner diameter="30"></mat-spinner>
			</div>
		</div>
		<div
			class="failed-to-load-more p-l-sm p-r-sm text-center"
			*ngIf="failToFetchMoreNotifications$ | async"
		>
			<p class="body-2 grey">
				Failed to load more notifications. Try again later or press
				<span (click)="loadMoreNotifications()" class="link-button cursor-pointer"
					>here</span
				>
			</p>
		</div>
		<div
			*ngIf="
				(loadingMoreNotifications$ | async) === false &&
				(failToFetchMoreNotifications$ | async) === false
			"
			class="no-more-notifications text-center"
		>
			<mat-icon svgIcon="flag"></mat-icon>
			<p class="body-2">
				That's all your notifications from <br />
				the last 30 days.
			</p>
		</div>
	</div>
</ng-template>

<ng-template #emptyNotificationsContainer>
	<div class="no-notifications" fxLayout="column" fxLayoutAlign="center center">
		<img src="./assets/img/maskot/empty-notification.svg" alt="no notification picture" />
		<p class="no-data empty-message">
			{{
				(failToFetchMessage$ | async)
					? 'Failed to fetch notifications'
					: 'There is no notifications yet'
			}}
		</p>
	</div>
</ng-template>

<!--Notifications popups container-->
<au-notifications-toaster></au-notifications-toaster>
<!--Notifications popups container-->
