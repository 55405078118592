import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@app/configs/reducers.config';
import { isLoggedIn } from '@auth/state/auth.selectors';
import { map } from 'rxjs/operators';
import config from '@app/configs/au-main-config';

@Injectable({
	providedIn: 'root',
})
export class AutilityAuthGuard {
	constructor(
		private store$: Store<IAppState>,
		private router: Router
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		return this.store$.pipe(
			select(isLoggedIn),
			map(loggedIn => {
				if (!loggedIn) {
					this.router.navigateByUrl(config.auRoutes.login.link);
					return false;
				}
				return true;
			})
		);
	}
}
