<ng-container *ngIf="selectedChatId()">
	<div class="chat-conversation-wrapper nice-scroll visible-scroll colored-scroll-track">
		<div class="chat-conversation-section">
			<chat-building-selection [class.m-b-xs]="!!chatMessages().length" />
			<div class="messages-container">
				@for (message of chatMessages(); track message.id; let last = $last) {
					<user-typed-message
						*ngIf="message.sender === 'user'"
						[message]="message"
						[avatarData]="profile()"
					/>
					@if (message.sender === 'chat') {
						<ai-response-message [message]="message" />
						@if (!last || !isLoadingReply()) {
							<div class="message-separator">
								<span class="dash"></span>
								<span class="description">Conversation ended</span>
								<span class="dash"></span>
							</div>
						}
					}
				}
			</div>
		</div>
	</div>
	<chat-input-field />
</ng-container>
