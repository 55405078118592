import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
	INotification,
	INotificationObjectType,
} from '@core/notifications/interfaces/notifications.interfaces';
import { iconEnum } from '@app/configs/model/IconEnum';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/configs/reducers.config';
import { markNotificationAsRead } from '@core/notifications/state/notifications.actions';
import { NOTIFICATION_OBJECT_CONFIG } from '@core/notifications/configs/notification-object.config';

@Component({
	selector: 'au-notification-message',
	templateUrl: './au-notification-message.component.html',
	styleUrls: ['./au-notification-message.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuNotificationMessageComponent {
	@Input() notification: INotification;
	@Input() isLastItem: boolean;

	//Based on INotificationObjectType
	readonly notificationIconsMap = {
		request: iconEnum.request,
		work_order: iconEnum.cmms,
		incident: iconEnum.incident,
	};

	readonly notificationObjectConfig = NOTIFICATION_OBJECT_CONFIG;

	constructor(
		private router: Router,
		private store$: Store<IAppState>
	) {}

	messageClicked() {
		this.markNotificationAsRead();

		const objectType = this.notification.object?.type;

		if (!objectType || objectType === 'blank') {
			return;
		}
		this.openNotificationObjectRelatedEntity(objectType);
	}

	private markNotificationAsRead() {
		if (!this.notification.isRead) {
			this.store$.dispatch(markNotificationAsRead({ pk: this.notification.pk }));
		}
	}

	private openNotificationObjectRelatedEntity(objectType: INotificationObjectType) {
		this.router.navigate([this.notificationObjectConfig[objectType].path]).then(() => {
			this.store$.dispatch(
				this.notificationObjectConfig[objectType].actionEvent({
					pk: this.notification.object.pk,
				})
			);
		});
	}

	//TODO Will be used in the future
	get notificationObjectIcon(): string {
		if (!this.notification.object?.type) {
			return null;
		}
		return this.notificationIconsMap[this.notification.object.type];
	}
}
