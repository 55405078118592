import { createReducer, on } from '@ngrx/store';
import { INotification } from '@core/notifications/interfaces/notifications.interfaces';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as NotificationsActions from './notifications.actions';
import { PaginationPageInfo } from '@shared/ui-components/au-paginator/pagination-page.info';

export interface INotificationsState extends EntityState<INotification> {
	// additional entities state properties
	notificationsIsLoading: boolean;
	loadingMoreNotifications: boolean;
	hasNewNotifications: boolean;
	notificationsPagination: { pageInfo: PaginationPageInfo };
	failToFetchMessage: boolean;
	failToLoadMoreNotifications: boolean;
}

export function selectNotificationPk(notification: INotification): string {
	return notification.pk;
}

export const notificationsAdapter: EntityAdapter<INotification> =
	createEntityAdapter<INotification>({
		selectId: selectNotificationPk,
	});

export const initialState: INotificationsState = notificationsAdapter.getInitialState({
	// additional entity state properties
	loadingMoreNotifications: false,
	notificationsIsLoading: true,
	hasNewNotifications: false,
	notificationsPagination: null,
	failToFetchMessage: false,
	failToLoadMoreNotifications: false,
});

export const notificationsReducer = createReducer(
	initialState,
	on(NotificationsActions.addNotification, (state, action) =>
		notificationsAdapter.addOne(action.notification, state)
	),
	on(NotificationsActions.upsertNotification, (state, action) =>
		notificationsAdapter.upsertOne(action.notification, state)
	),
	on(NotificationsActions.addNotifications, (state, action) =>
		notificationsAdapter.addMany(action.notifications, state)
	),
	on(NotificationsActions.upsertNotifications, (state, action) =>
		notificationsAdapter.upsertMany(action.notifications, state)
	),
	on(NotificationsActions.updateNotification, (state, action) =>
		notificationsAdapter.updateOne(action.notification, state)
	),
	on(NotificationsActions.updateNotificationWithReadStatus, (state, action) =>
		notificationsAdapter.updateOne(action.notification, {
			...state,
			hasNewNotifications: action.unreadMsgLeft,
		})
	),
	on(NotificationsActions.updateNotifications, (state, action) =>
		notificationsAdapter.updateMany(action.notifications, state)
	),
	on(NotificationsActions.deleteNotification, (state, action) =>
		notificationsAdapter.removeOne(action.pk, state)
	),
	on(NotificationsActions.deleteNotifications, (state, action) =>
		notificationsAdapter.removeMany(action.pks, state)
	),
	on(
		NotificationsActions.notificationsLoaded,
		(state, { notifications, notificationsPagination, hasUnreadNotifications }) => {
			return notificationsAdapter.setAll(notifications, {
				...state,
				notificationsIsLoading: false,
				hasNewNotifications: hasUnreadNotifications,
				notificationsPagination,
			});
		}
	),
	on(NotificationsActions.clearNotifications, state =>
		notificationsAdapter.removeAll(state)
	),
	on(NotificationsActions.loadNotificationsFailure, state => ({
		...state,
		notificationsIsLoading: false,
		failToFetchMessage: true,
	})),
	on(NotificationsActions.loadMoreNotifications, state => ({
		...state,
		//prettier-ignore
		loadingMoreNotifications: !!state.notificationsPagination.pageInfo.hasNextPage,
	})),
	on(
		NotificationsActions.loadMoreNotificationsSuccess,
		(state, { notifications, notificationsPagination, hasUnreadNotifications }) => {
			return notificationsAdapter.addMany(notifications, {
				...state,
				loadingMoreNotifications: false,
				hasNewNotifications: hasUnreadNotifications,
				notificationsPagination,
				failToLoadMoreNotifications: false,
			});
		}
	),
	on(NotificationsActions.loadMoreNotificationsFailure, state => ({
		...state,
		loadingMoreNotifications: false,
		failToLoadMoreNotifications: true,
	})),
	on(NotificationsActions.mapNotifications, (state, { mapper }) => {
		return notificationsAdapter.map(mapper, {
			...state,
			hasNewNotifications: false,
		});
	})
);

const { selectIds, selectEntities, selectAll, selectTotal } =
	notificationsAdapter.getSelectors();

export const notificationsEntitySelector = {
	selectIds,
	selectEntities,
	selectAll,
	selectTotal,
};
