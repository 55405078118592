import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
	INotificationsPaginatedData,
	INotificationsQueryVariables,
} from '@core/notifications/interfaces/notifications.interfaces';
import { NotificationsQuery } from '@core/api/notifications.query';
import { map } from 'rxjs/operators';
import { PaginationPageInfo } from '@shared/ui-components/au-paginator/pagination-page.info';
import {
	markAllNotificationAsReadMutation,
	markNotificationAsReadMutation,
} from '@core/api/notifications.mutations';

@Injectable({
	providedIn: 'root',
})
export class AuNotificationsService {
	constructor(private apollo: Apollo) {}

	loadNotifications(variables: INotificationsQueryVariables) {
		return this.apollo
			.use('federation')
			.query<INotificationsPaginatedData>({
				query: NotificationsQuery,
				fetchPolicy: 'network-only',
				variables,
			})
			.pipe(
				map(({ data }) => {
					const notifications = data.notifications.edges.map(edge => edge.node);
					return {
						notifications,
						notificationsPagination: {
							pageInfo: data.notifications.pageInfo,
						},
						hasUnreadNotifications: data.notifications.hasUnread,
					};
				})
			);
	}

	loadMoreNotifications(pageInfo: PaginationPageInfo) {
		return this.loadNotifications({ first: 10, after: pageInfo.endCursor });
	}

	markNotificationAsRead(notificationPk: string) {
		// TODO change to use federation. This use cmms just because BE hadn't update federation T_T
		return this.apollo
			.use('cmms')
			.mutate<{
				markNotificationAsRead: { success: boolean; hasUnread: boolean };
			}>({
				mutation: markNotificationAsReadMutation,
				variables: { pk: notificationPk },
			})
			.pipe(map(({ data }) => data?.markNotificationAsRead?.hasUnread));
	}

	markAllNotificationsAsRead() {
		return this.apollo
			.use('federation')
			.mutate({
				mutation: markAllNotificationAsReadMutation,
			})
			.pipe(map(({ data }) => data));
	}
}
