import { Pipe, PipeTransform } from '@angular/core';
import { IAiChatSources } from '@app/ai-chat/interfaces/ai-chat-response.interface';

@Pipe({
	name: 'uniqueSourceUrls',
	standalone: true,
})
export class UniqueSourceUrlsPipe implements PipeTransform {
	transform(sources: IAiChatSources[]): IAiChatSources[] {
		if (!sources || sources.length === 0) {
			return [];
		}

		// Create a Set to store unique source URLs
		const uniqueUrls = new Set<string>();

		// Filter the sources array to keep only unique source URLs
		return sources.filter(source => {
			if (!uniqueUrls.has(source.url)) {
				// Add the URL to the Set if it's not already present
				uniqueUrls.add(source.url);
				return true; // Include the source in the filtered array
			}
			return false; // Exclude duplicate sources
		});
	}
}
