<div class="au-breadcrumbs-container">
	<nav>
		<ng-container *ngFor="let item of breadcrumbs(); let last = last">
			<h4>
				<ng-container *ngIf="item.path">
					<a class="primary-color" [routerLink]="item.path">{{ item.label }}</a>
				</ng-container>
				<ng-container *ngIf="!item.path">
					<span>{{ item.label }}</span>
				</ng-container>
			</h4>
			<span *ngIf="!last" class="success-icon icon"> > </span>
		</ng-container>
	</nav>

	<ng-container *ngIf="menu()?.items">
		<div
			mat-ripple
			class="breadcrumb-actions ripple-container"
			[matMenuTriggerFor]="menuTrigger"
		>
			<mat-icon>more_vert</mat-icon>
		</div>
	</ng-container>
</div>

<mat-menu #menuTrigger="matMenu" class="au-mat-menu-actions-list" xPosition="after">
	<div class="breadcrumbs-actions-menu">
		<ng-container *ngFor="let menuItem of menu()?.items">
			<mat-divider *ngIf="menuItem.divider" />
			<div
				class="menu-action"
				(click)="menuItem.action()"
				[ngClass]="menuItem.colorPalette + '-action'"
			>
				<mat-icon [svgIcon]="menuItem.icon"></mat-icon>
				<span>{{ menuItem.name }}</span>
			</div>
		</ng-container>
	</div>
</mat-menu>
