import { Component, computed, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { VersionCheckService } from './version-check.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'version-check',
	templateUrl: './version-check.component.html',
	styleUrl: './version-check.component.scss',
	standalone: true,
	imports: [MatButtonModule, MatCardModule, MatIconModule],
})
export class VersionCheckComponent implements OnInit {
	private readonly versionService = inject(VersionCheckService);
	private readonly destroyRef = inject(DestroyRef);

	private readonly newVersion = signal<string>(undefined);
	private readonly ignore = signal<boolean>(false);

	readonly showVersionPopup = computed(() => {
		return !this.ignore() && this.newVersion() != null;
	});

	ngOnInit(): void {
		this.subscribeToVersionCheck();
	}

	public close() {
		this.ignore.set(true);
	}

	public reload() {
		window.location.reload();
	}

	private subscribeToVersionCheck() {
		this.versionService.newVersionAvailable$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(versions => {
				this.newVersion.set(versions.available);
			});
	}
}
