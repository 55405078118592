<div
	class="notification-message"
	[class.unread]="!notification.isRead"
	(click)="messageClicked()"
>
	<div class="notification-avatar">
		<au-round-avatar [data]="notification.createdBy"></au-round-avatar>
	</div>
	<div class="notification-body">
		<h5 class="notification-title">
			<!--			TODO This would be used later-->
			<!--			<span class="notification-type-icon">-->
			<!--				<mat-icon-->
			<!--					*ngIf="notification.object?.type !== 'blank'"-->
			<!--					[svgIcon]="notificationObjectIcon"-->
			<!--				></mat-icon>-->
			<!--			</span>-->
			{{ notification.title }}
			<span class="notification-timestamp">
				{{ notification.created | dateAgo }}
			</span>
		</h5>
		<div class="notification-event">
			<p class="body-2">{{ notification.event }}</p>
		</div>
		<div *ngIf="notification.text" class="notification-text">
			<p class="body-2">{{ notification.text }}</p>
		</div>
		<div class="notification-footer">
			{{ notification.footer }}
		</div>
	</div>
	<div *ngIf="!isLastItem" class="bottom-border"></div>
</div>
