import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	inject,
	DestroyRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { liveQuery } from 'dexie';
import { DatabaseService } from '@app/database/database.service';
import { AiChatService } from '@app/ai-chat/services/ai-chat.service';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

@Component({
	selector: 'chat-building-selection',
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		MatFormFieldModule,
		MatOptionModule,
		MatSelectModule,
		ReactiveFormsModule,
	],
	templateUrl: './chat-building-selection.component.html',
	styleUrls: ['./chat-building-selection.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBuildingSelectionComponent implements OnInit {
	private readonly dbService = inject(DatabaseService);
	private readonly aiChatService = inject(AiChatService);
	private readonly destroyRef = inject(DestroyRef);

	selectedBuilding$ = toObservable(this.aiChatService.selectedBuilding);

	buildings$ = liveQuery(() => this.dbService.buildings?.orderBy('name').toArray());

	buildingControl = new FormControl();

	ngOnInit() {
		this.selectedBuilding$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(buildingId => {
				this.buildingControl.setValue(buildingId);
			});
	}

	onBuildingChange($event: MatSelectChange) {
		this.aiChatService.selectChatBuilding($event.value);
		this.aiChatService.loadChats();
	}
}
