import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	AuthActionTypes,
	CurrentUserFailure,
	LoadAuthTranslate,
	LoadAuthTranslateFailure,
	LoadAuthTranslateSuccess,
	LogIn,
	LogInFailure,
	LogInSuccess,
	PasswordReset,
	PasswordResetEmailSent,
	PasswordResetFailure,
	PasswordResetSuccess,
	SendNewPassword,
	SendNewPasswordFailure,
	SendNewPasswordSuccess,
	SetUserPassword,
	SetUserPasswordFailure,
	SetUserPasswordSuccess,
	UnfollowBuilding,
	UnfollowBuildingSuccess,
	UpdateFollowedBuildings,
	UpdateFollowedBuildingsSuccess,
} from './auth.actions';
import {
	catchError,
	exhaustMap,
	map,
	switchMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth.service';
import config from '@app/configs/au-main-config';
import { IUser } from '@app/shared/interfaces/user.interface';
import { IAuthState } from '@auth/state/auth.reducer';
import { Store } from '@ngrx/store';
import {
	getAfterPasswordChangedData,
	getAuthTranslate,
} from '@auth/state/auth.selectors';
import { AuProfileService } from '@app/core/services/au-profile.service';
import { IconSnackBarService } from '@core/services/icon-snack-bar.service';
import { AuErrorAdapter } from '@app/shared';

@Injectable()
export class AuthEffects {
	LogIn: Observable<any> = createEffect(() =>
		this.actions.pipe(
			ofType(AuthActionTypes.LOGIN),
			map((action: LogIn) => action.payload),
			exhaustMap(payload => {
				return this.authService.logIn(payload.email, payload.password).pipe(
					map((res: { token: string }) => new LogInSuccess({ token: res.token })),
					catchError(res => {
						const errorMsg = res.error?.error;
						this.notify.error(errorMsg || 'Something went wrong');
						return of(new LogInFailure());
					})
				);
			})
		)
	);

	// @ts-ignore
	LogInSuccess: Observable<any> = createEffect(
		() =>
			this.actions.pipe(
				ofType(AuthActionTypes.LOGIN_SUCCESS),
				switchMap(() => {
					return this.authService.getCurrentUser().pipe(
						map((profile: IUser) => {
							this.auProfileService.setUserProfile(profile);
						}),
						catchError(error => of(new CurrentUserFailure({ error })))
					);
				}),
				tap(() => {
					this.router.navigate([config.auRoutes.dashboard.link]);
				})
			),
		{ dispatch: true }
	);

	PasswordReset: Observable<any> = createEffect(() =>
		this.actions.pipe(
			ofType(AuthActionTypes.PASSWORD_RESET),
			map((action: PasswordReset) => action.payload.email),
			exhaustMap(payload => {
				return this.authService.recoveryPassword(payload).pipe(
					map(() => {
						return new PasswordResetSuccess();
					}),
					catchError(res => {
						return of(new PasswordResetFailure(res.error));
					})
				);
			})
		)
	);

	PasswordResetSuccess: Observable<any> = createEffect(() =>
		this.actions.pipe(
			ofType(AuthActionTypes.PASSWORD_RESET_SUCCESS),
			withLatestFrom(this.store$.select(getAuthTranslate)),
			tap(([_, translations]) => {
				this.notify.success(translations.recovery_password.status, 'infinite');
			}),
			map(() => new PasswordResetEmailSent())
		)
	);

	PasswordResetFailure: Observable<any> = createEffect(
		() =>
			this.actions.pipe(
				ofType(AuthActionTypes.PASSWORD_RESET_FAILURE),
				withLatestFrom(this.store$.select(getAuthTranslate)),
				tap(([data, translations]) => {
					// @ts-ignore
					this.notify.error(translations.errors[data.payload.error[0]]);
				})
			),
		{ dispatch: false }
	);

	LogOut: Observable<any> = createEffect(
		() =>
			this.actions.pipe(
				ofType(AuthActionTypes.LOGOUT),
				tap(() => {
					this.authService.logout();
					this.router.navigateByUrl(config.auRoutes.login.link);
				})
			),
		{ dispatch: false }
	);

	LoadAuthTranslate: Observable<any> = createEffect(() =>
		this.actions.pipe(
			ofType(AuthActionTypes.LOAD_AUTH_TRANSLATE),
			map((action: LoadAuthTranslate) => action.payload),
			exhaustMap(() => {
				return this.authService.loadAuthTranslation().pipe(
					map(data => {
						return new LoadAuthTranslateSuccess({ data });
					}),
					catchError(error => of(new LoadAuthTranslateFailure({ error })))
				);
			})
		)
	);

	SendNewPassword: Observable<any> = createEffect(() =>
		this.actions.pipe(
			ofType(AuthActionTypes.SEND_NEW_PASSWORD),
			map((action: SendNewPassword) => action.payload),
			exhaustMap(payload => {
				return this.authService.sendNewPassword(payload.password, payload.token).pipe(
					map(data => {
						return new SendNewPasswordSuccess({ data });
					}),
					catchError(error => of(new SendNewPasswordFailure({ error })))
				);
			})
		)
	);

	SendNewPasswordSuccess: Observable<any> = createEffect(
		() =>
			this.actions.pipe(
				ofType(AuthActionTypes.SEND_NEW_PASSWORD_SUCCESS),
				withLatestFrom(this.store$.select(getAfterPasswordChangedData)),
				take(1),
				tap(([_, passwordChangedData]) => {
					if (passwordChangedData.isLoggedIn) {
						this.authService.logout();
					}
					this.router.navigateByUrl(config.auRoutes.login.link);
					this.notify.success(passwordChangedData.message);
				})
			),
		{ dispatch: false }
	);

	SendNewPasswordFailure: Observable<any> = createEffect(
		() =>
			this.actions.pipe(
				ofType(AuthActionTypes.SEND_NEW_PASSWORD_FAILURE),
				tap(error => {
					console.error(error);
					this.notify.error('Password change has failed');
				})
			),
		{ dispatch: false }
	);

	SetUserPassword: Observable<any> = createEffect(() =>
		this.actions.pipe(
			ofType(AuthActionTypes.SET_USER_PASSWORD),
			map((action: SetUserPassword) => action.payload),
			exhaustMap(payload => {
				return this.authService.setUserPassword(payload.password, payload.token).pipe(
					map(data => {
						return new SetUserPasswordSuccess({ data });
					}),
					catchError(error => of(new SetUserPasswordFailure({ error })))
				);
			})
		)
	);

	SetUserPasswordSuccess: Observable<any> = createEffect(
		() =>
			this.actions.pipe(
				ofType(AuthActionTypes.SET_USER_PASSWORD_SUCCESS),
				tap(() => {
					this.router.navigateByUrl(config.auRoutes.login.link);
					this.notify.success(
						'Congratulations! Now you could login with your credentials'
					);
				})
			),
		{ dispatch: false }
	);

	SetUserPasswordFailure: Observable<any> = createEffect(
		() =>
			this.actions.pipe(
				ofType(AuthActionTypes.SET_USER_PASSWORD_FAILURE),
				map((action: SetUserPasswordFailure) => action.payload),
				tap(payload => {
					console.error(payload.error);
					const errorMessage = payload.error.error || 'Something went wrong';
					this.notify.error(errorMessage);
				})
			),
		{ dispatch: false }
	);

	UpdateUserFollowedBuildings: Observable<any> = createEffect(() =>
		this.actions.pipe(
			ofType(AuthActionTypes.UPDATE_FOLLOWED_BUILDINGS),
			map((action: UpdateFollowedBuildings) => action.payload),
			exhaustMap(payload => {
				return this.auProfileService.followBuilding(payload).pipe(
					map(data => {
						return new UpdateFollowedBuildingsSuccess(data);
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return of(error);
					})
				);
			})
		)
	);

	UnfollowBuilding: Observable<any> = createEffect(() =>
		this.actions.pipe(
			ofType(AuthActionTypes.UNFOLLOW_BUILDING),
			map((action: UnfollowBuilding) => action.payload),
			exhaustMap(buildingId => {
				return this.auProfileService.unfollowBuilding(buildingId).pipe(
					map(data => {
						return new UnfollowBuildingSuccess(data);
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return of(error);
					})
				);
			})
		)
	);

	constructor(
		private actions: Actions,
		private router: Router,
		private authService: AuthService,
		private notify: IconSnackBarService,
		private store$: Store<IAuthState>,
		private auProfileService: AuProfileService
	) {}
}
