import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	AddItemToNavigationHistory,
	AssigneeListLoaded,
	GotoPreviousPageOnRightSidebar,
	LoadAssignees,
	LoadSelectableOptions,
	NavigateToSidebarPage,
	openAiChat,
	SelectableAssigneesFailedToLoad,
	SelectableOptionsFailedToLoad,
	SelectableOptionsLoaded,
	SetNavigationHistory,
} from '@layout/state/layout.actions';
import {
	catchError,
	filter,
	map,
	pluck,
	switchMap,
	withLatestFrom,
} from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { of } from 'rxjs';
import {
	IAssignee,
	ISelectableOptions,
	ISelectableOptionsQuery,
} from '@app/shared/interfaces/selectable-options.interfaces';
import {
	SelectableAssigneesQuery,
	SelectableOptionsQuery,
} from '@layout/graphql/selectable-options.queries';
import { ACTIVE_PAGE_CONFIG } from '@app/pages/cmms/configs/active-page.config';
import { AuRightSidebarService } from '@core/services/au-right-sidebar.service';
import { Store } from '@ngrx/store';
import { rightSideBarNavigation } from '@layout/state/layout.selectors';
import { AuUtilsFunctions } from '@app/shared';
import { DatabaseService } from '@app/database/database.service';
import { RightSidebarNavigationService } from '@layout/au-right-sidebar/right-sidebar-navigation.service';
import { AiChatComponent } from '@app/ai-chat/ai-chat.component';

@Injectable()
export class LayoutEffects {
	loadSelectableOptions$ = createEffect((): any =>
		this.actions$.pipe(
			ofType(LoadSelectableOptions),
			switchMap(() => {
				return this.apollo
					.use('federation')
					.query<ISelectableOptionsQuery>({
						query: SelectableOptionsQuery,
						fetchPolicy: 'no-cache',
					})
					.pipe(
						pluck('data', 'availableVariables'),
						map(data => {
							const options: ISelectableOptions = {
								buildings: data.buildings,
								organizations: data.organizations,
								roomTypes: data.roomTypes,
								organizationTypes: data.organization_types,
								teamTypes: data.team_types,
							};

							this.dbService.storeAppSelectableOptions(options);

							return SelectableOptionsLoaded({
								selectableOptions: options,
							});
						}),
						catchError(error => {
							return of(SelectableOptionsFailedToLoad({ error }));
						})
					);
			})
		)
	);
	loadAssigneeList$ = createEffect((): any =>
		this.actions$.pipe(
			ofType(LoadSelectableOptions, LoadAssignees),
			switchMap(() => {
				return this.apollo
					.use('federation')
					.query<ISelectableOptionsQuery>({
						query: SelectableAssigneesQuery,
						fetchPolicy: 'no-cache',
					})
					.pipe(
						pluck('data', 'organizations'),
						map((assignees: IAssignee[]) => {
							this.dbService.putItemBulk('assignees', assignees);
							return AssigneeListLoaded({ assignees });
						}),
						catchError(error => {
							return of(SelectableAssigneesFailedToLoad({ error }));
						})
					);
			})
		)
	);

	NavigateToSidebarPage$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(NavigateToSidebarPage),
			map(({ page, config }) => {
				const navigationData = ACTIVE_PAGE_CONFIG[page];

				this.sidebarService.setComponentAndOpen(navigationData.createComponent, {
					...navigationData.sidebarConfig,
					...config,
				});

				return AddItemToNavigationHistory({
					config: navigationData.sidebarConfig,
					component: page,
				});
			})
		);
	});

	gotoPreviousPageOnRightSidebar$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(GotoPreviousPageOnRightSidebar),
			withLatestFrom(this.store$.select(rightSideBarNavigation)),
			filter(([_, navigation]) => {
				return !!navigation.history.length;
			}),
			map(([_, navigation]) => {
				const newHistory = AuUtilsFunctions.deepCopyObject(navigation.history);
				newHistory.pop();

				if (newHistory.length || navigation.initialComponent) {
					this.sidebarService.setComponentAndOpen(
						this.rightSidebarNavigationService.getComponentByAlias(
							navigation.initialComponent
						),
						navigation.config
					);
				} else {
					this.sidebarService.close();
				}

				return SetNavigationHistory({ history: newHistory });
			})
		);
	});

	openAiChat$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(openAiChat),
				withLatestFrom(this.sidebarService.sidebarMinimized$),
				map(([_, minimized]) => {
					if (minimized) {
						this.sidebarService.undoMinimize();
					} else {
						this.sidebarService.setComponentAndOpen(AiChatComponent, {
							allowFullscreen: true,
							propagateCloseEvent: true,
							showContentActions: false,
							noIcon: true,
							disableDefaultScroll: true,
						});
					}
				})
			);
		},
		{ dispatch: false }
	);

	constructor(
		private store$: Store,
		private actions$: Actions,
		private apollo: Apollo,
		private sidebarService: AuRightSidebarService,
		private dbService: DatabaseService,
		private rightSidebarNavigationService: RightSidebarNavigationService
	) {}
}
