<div class="policy-container">
	<div class="gdpr-logo m-r-sxs">
		<img src="./assets/img/gdpr.png" alt="eu like styled image" />
	</div>
	<div class="content">
		<div class="description">
			<p>
				In accordance with <strong>GDPR</strong>, we would like to obtain your consent to
				the processing of your user data.
				<a class="link-button" href="https://www.autility.no/privacy" target="_blank"
					>Read Privacy Policy</a
				>
			</p>
		</div>
		<button (click)="accept()" mat-flat-button class="primary-btn">Accept</button>
	</div>
</div>
