import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getToken } from '@auth/state/auth.selectors';
import config from '@app/configs/au-main-config';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	escapedUrls = new Set([
		config.auRoutes.login.link,
		config.auRoutes.passwordReset.link,
		config.auRoutes.recoveryPassword.link,
		config.auRoutes.setPassword.link,
	]);

	constructor(
		private router: Router,
		private store$: Store<any>
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.addToken(request).pipe(
			first(),
			mergeMap((requestWithToken: HttpRequest<any>) => next.handle(requestWithToken))
		);
	}

	private addToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
		return this.store$.pipe(
			select(getToken),
			mergeMap((token: string) => {
				const url = this.router.url ? this.router.url.replace('/', '').split('?')[0] : '';
				if (token) {
					request = request.clone({
						headers: request.headers.set('Authorization', `Bearer ${token}`),
					});
				} else if (!token && url && !this.escapedUrls.has(url)) {
					console.warn(
						`Token { ${token} } is not valid!`,
						url,
						config.auRoutes.login.link
					);
				}
				return of(request);
			}),
			catchError(err => {
				console.error(err);
				return of(request);
			})
		);
	}
}
