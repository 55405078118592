import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { setAuBreadcrumb } from '@app/layout/state/layout.actions';

@Injectable({
	providedIn: 'root',
})
export class AuBreadcrumbsService {
	private readonly store$ = inject(Store);
	private readonly router = inject(Router);

	constructor() {
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => getActiveRoute(this.router.routerState.snapshot.root)),
				map(activeRoute => getBreadcrumbs(activeRoute))
			)
			.subscribe(crumbs => {
				this.store$.dispatch(
					setAuBreadcrumb({
						configuration: crumbs,
					})
				);
			});
	}
}

function getActiveRoute(rootRoute: ActivatedRouteSnapshot) {
	let currentRoute = rootRoute;
	let currentLevel = 0; // safeguard
	while (currentRoute.firstChild != null && currentLevel < 100) {
		currentLevel++;
		currentRoute = currentRoute.firstChild;
	}
	return currentRoute;
}

function getBreadcrumbs(activeRoute: ActivatedRouteSnapshot) {
	const crumbs: string[] = activeRoute.pathFromRoot
		.map(route => route.data.breadcrumb)
		.filter(crumb => crumb != null);

	return removeDuplicatedCrumbs(crumbs).map(crumb => ({ label: crumb }));
}

function removeDuplicatedCrumbs(crumbs: string[]): string[] {
	const deduplicatedCrumbs: string[] = [];
	crumbs.forEach(crumb => {
		if (
			deduplicatedCrumbs.length === 0 ||
			(deduplicatedCrumbs.length > 0 &&
				deduplicatedCrumbs[deduplicatedCrumbs.length - 1] !== crumb)
		) {
			deduplicatedCrumbs.push(crumb);
		}
	});
	return deduplicatedCrumbs;
}
