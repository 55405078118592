<div class="navbar-menu-items-container nice-scroll colored-scroll-track">
	<ng-container *ngFor="let menuItem of menuItems">
		<div class="menu-item-wrapper" *ngIf="isMenuItemVisible(menuItem)">
			<menu-item-content
				[menuItem]="menuItem"
				[level]="0"
				(expandedChange)="onExpandToggle()"
			/>
		</div>
	</ng-container>
	<my-buildings-section
		class="m-t-xs"
		*ngIf="!isSettingsRoute && isViewExtraFlagEnabled"
	/>
</div>
