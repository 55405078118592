import {
	computed,
	inject,
	Injectable,
	Signal,
	signal,
	WritableSignal,
} from '@angular/core';
import { Router } from '@angular/router';
import {
	selectCurrentBuildingId,
	selectFolders,
	selectIsLoadingFolders,
} from '@app/pages/views/state/views.reducer';
import { Store } from '@ngrx/store';
import { ViewsActions } from '@app/pages/views/state/views.actions';
import { IAuNavbarConfig } from '@app/configs/au-navbar.config';

@Injectable({
	providedIn: 'root',
})
export class NavbarNavigationService {
	private readonly router = inject(Router);
	private readonly store$ = inject(Store);
	activeRoute: WritableSignal<string> = signal(this.router.url.substring(1));
	currentBuildingId = this.store$.selectSignal(selectCurrentBuildingId);
	getFolders = this.store$.selectSignal(selectFolders);
	isLoadingFolders: Signal<boolean> = this.store$.selectSignal(selectIsLoadingFolders);
	folders = computed(() => (!this.isLoadingFolders() ? this.getFolders() : []));

	currentActiveRoute = route => {
		this.activeRoute.set(route);
	};

	navigateToCreatePage(id) {
		this.store$.dispatch(
			ViewsActions.createViewInsideFolder({
				folderPk: id,
			})
		);
	}

	getChildViews(buildingId = '') {
		let storedState = null;

		if (localStorage.getItem('navbarGlobalViewMenuState') !== 'undefined') {
			storedState = JSON.parse(localStorage.getItem('navbarGlobalViewMenuState'));
		}
		if (
			this.folders().length === 0 ||
			(!buildingId ? this.currentBuildingId() : buildingId !== this.currentBuildingId())
		) {
			return {
				title: 'Views',
				svgIcon: 'table-view',
				routerLink: 'views/folders-list',
			};
		}

		const global = buildingId ? [] : storedState?.children;

		const children = this.folders().map((view, index): IAuNavbarConfig => {
			const child = {
				title: view.name,
				routerLink: `${view.pk}/view`,
				page: false,
				expanded: this.activeRoute().includes(view.pk)
					? this.activeRoute().includes(view.pk)
					: global?.[index]?.expanded,
				className: 'with-background',
				children: view.uiTableViewConfigs
					.map(childView => ({
						title: childView.name,
						routerLink: childView.pk,
					}))
					.sort((a, b) => a.title.localeCompare(b.title)),
			} as IAuNavbarConfig;

			if (!view.uiTableViewConfigs.length) {
				child.onClickAction = () => {
					this.navigateToCreatePage(view.pk);
				};
			}
			return child;
		});

		return {
			title: 'Views',
			svgIcon: 'table-view',
			routerLink: 'views/folders-list',
			className: 'folder',
			expanded: true,
			children: children,
		};
	}
}
