import { createSelector } from '@ngrx/store';
import {
	INotificationsState,
	notificationsEntitySelector,
} from '@core/notifications/state/notifications.reducer';

export const selectNotificationsState = state => state.layout.notifications;

export const allNotifications = createSelector(
	selectNotificationsState,
	(state: INotificationsState) => notificationsEntitySelector.selectAll(state)
);

export const notificationsIsLoading = createSelector(
	selectNotificationsState,
	(state: INotificationsState) => state.notificationsIsLoading
);

export const moreNotificationsIsLoading = createSelector(
	selectNotificationsState,
	(state: INotificationsState) => state.loadingMoreNotifications
);

export const notificationsPagination = createSelector(
	selectNotificationsState,
	(state: INotificationsState) => state.notificationsPagination
);

export const hasNewNotifications = createSelector(
	selectNotificationsState,
	(state: INotificationsState) => state.hasNewNotifications
);

export const failToFetchMessage = createSelector(
	selectNotificationsState,
	(state: INotificationsState) => state.failToFetchMessage
);

export const failToFetchMoreNotifications = createSelector(
	selectNotificationsState,
	(state: INotificationsState) => state.failToLoadMoreNotifications
);
