<div class="topbar-action-buttons-container">
	<div
		class="ai-chat-toggle-icon"
		*ngIf="isAiChatEnabled()"
		matRipple
		(click)="openAiChat()"
	>
		<mat-icon svgIcon="robot" [class.active]="isAiChatOpened()" />
	</div>
	<div class="header-notification-icon">
		<au-notifications />
	</div>
</div>
