import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
	providedIn: 'root',
})
export class WithKeycloakAuthGuard extends KeycloakAuthGuard {
	constructor(
		protected readonly router: Router,
		protected readonly keycloak: KeycloakService
	) {
		super(router, keycloak);
	}

	async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// Force the user to log in if currently unauthenticated.
		if (!this.authenticated) {
			const redirectUri = window.location.origin + environment.baseHref + state.url;
			await this.keycloak.login({
				redirectUri,
			});

			this.keycloak.getToken();
		}

		// Get the roles required from the route.
		const requiredRoles = route.data.roles;

		// Allow the user to to proceed if no additional roles are required to access the route.
		if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
			return true;
		}

		// Allow the user to proceed if all the required roles are present.
		return requiredRoles.every(role => this.roles.includes(role));
	}
}
