import { inject, Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateChild,
	GuardResult,
	MaybeAsync,
	RouterStateSnapshot,
} from '@angular/router';
import { VersionCheckService } from './version-check.service';

@Injectable({
	providedIn: 'root',
})
export class VersionCheckGuard implements CanActivateChild {
	private readonly versionCheckService = inject(VersionCheckService);

	canActivateChild(
		_route: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot
	): MaybeAsync<GuardResult> {
		if (this.versionCheckService.newVersionAvailable() != null) {
			// When a new version is available, we reload the page on the next navigation
			window.location.replace(_state.url);
		}
		return true;
	}
}
