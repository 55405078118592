import { AfterViewInit, Directive, ElementRef, OnDestroy, output } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[resizeListener]',
})
export class ResizeDirective implements AfterViewInit, OnDestroy {
	private resizeObserver: ResizeObserver;

	resized = output<DOMRectReadOnly>();

	constructor(private el: ElementRef<HTMLElement>) {}

	ngAfterViewInit(): void {
		if (this.el) {
			this.resizeObserver = new ResizeObserver(entries => {
				if (entries[0]?.contentRect) {
					this.resized.emit(entries[0]?.contentRect);
				}
			});
			this.resizeObserver.observe(this.el.nativeElement);
		}
	}

	ngOnDestroy(): void {
		this.resizeObserver.disconnect();
	}
}
