<mat-card appearance="outlined" fxFlex fxLayout="column" fxLayoutAlign="start center">
	<mat-card-header fxLayout="column">
		<mat-icon class="logo" svgIcon="logo"></mat-icon>
		<mat-card-title fxFlexOrder="1">
			<h2>{{ (authTranslation$ | async)?.login.title }}</h2></mat-card-title
		>
	</mat-card-header>
	<mat-card-content>
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<mat-form-field hideRequiredMarker>
				<input
					autofocus
					type="text"
					matInput
					placeholder="{{ (authTranslation$ | async)?.login.email }}"
					formControlName="email"
					pattern="[aA-zZ0-9._%+-]+@[aA-zZ0-9.-]+\.[aA-zZ]{2,10}$"
				/>
				<mat-error>
					<span *ngIf="email.hasError('required')">You must enter a value</span>
					<span *ngIf="email.hasError('pattern')">Not a valid email</span>
				</mat-error>
			</mat-form-field>
			<mat-form-field hideRequiredMarker>
				<input
					#password
					type="password"
					matInput
					autocomplete="off"
					placeholder="{{ (authTranslation$ | async)?.login.password }}"
					formControlName="password"
				/>

				<mat-icon
					class="pointer"
					(click)="toggleInputType($event, password)"
					matSuffix
					[svgIcon]="getInputIcon(password.type)"
				></mat-icon>
				<mat-error>
					<span *ngIf="password.errors">{{ passwordErrorValidation }}</span>
				</mat-error>
			</mat-form-field>

			<button mat-raised-button color="primary" type="submit" mat-button>
				{{ (authTranslation$ | async)?.login.button }}
			</button>

			<a class="routerLink" routerLink="/recovery-password">
				<span>{{ (authTranslation$ | async)?.login.link | titlecase }}</span>
			</a>
		</form>
	</mat-card-content>
</mat-card>
