<div class="menu-item">
	<div
		class="menu-item-content"
		[class.has-action-btn]="menuItem.action"
		[ngClass]="'level-' + level"
		[routerLink]="currentLink()"
		(click)="onMenuItemClicked($event)"
		[class.active]="activeRoute()"
	>
		<mat-icon
			*ngIf="menuItem.svgIcon"
			class="menu-item-icon"
			[svgIcon]="menuItem.svgIcon"
		/>

		<p>{{ menuItem.title }}</p>

		<ng-container *ngIf="!!menuItem.children?.length && !alwaysExpanded">
			<mat-icon *ngIf="!menuItem.expanded" class="child-arrow-icon">
				arrow_right
			</mat-icon>
			<mat-icon *ngIf="menuItem.expanded" class="child-arrow-icon">
				arrow_drop_down
			</mat-icon>
		</ng-container>

		<div class="action-block" *ngIf="menuItem.action && !menuItem.onClickAction">
			<mat-icon
				*ngIf="!menuItem.action.isProvidedMenuAction; else providedMenuActionTrigger"
				[svgIcon]="menuItem.action.svgIcon"
				class="menu-item-action"
				[ngClass]="menuItem.action.className"
				(click)="onClickAction($event, menuItem.action.methodKey)"
			/>

			<ng-template #providedMenuActionTrigger>
				<mat-icon
					[svgIcon]="menuItem.action.svgIcon"
					class="menu-item-action"
					[ngClass]="menuItem.action.className"
					(click)="openMenu($event)"
				/>
				<span [matMenuTriggerFor]="actionMenu"></span>
			</ng-template>
		</div>
	</div>

	<ng-container *ngIf="menuItem.expanded">
		<div class="child-wrapper" [ngClass]="menuItem.className">
			<ng-container *ngFor="let child of menuItem.children">
				<menu-item-content
					[menuItem]="child"
					[level]="level + 1"
					[parentRouteLink]="parentRouteLinkForChild"
					(expandedChange)="expandedChange.emit()"
					*ngIf="isMenuItemVisible(child)"
				/>
			</ng-container>
		</div>
	</ng-container>
</div>

<mat-menu #actionMenu="matMenu" xPosition="after" yPosition="below">
	<ng-content select="[menuContent]" />
</mat-menu>
